import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Locale, Window, Title, Content } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCookies } from 'react-cookie';

export default function Header() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, setLocale, translations } = useContext(Locale);
    const { page } = useContext(Title);
    const { finished } = useContext(Content);

    const headerLinks = [
        {
            label: translations[locale].header.home,
            page: "home",
            url: "/",
        },
        {
            label: translations[locale].header.bicyclette,
            page: "bicyclette",
            url: "/universes/bicyclette-smoothie-energie",
        },
        {
            label: translations[locale].header.universe,
            page: "universes",
            url: "/universes",
        },
        {
            label: translations[locale].header.shop,
            page: "shop",
            url: "/shop",
        },
        {
            label: translations[locale].header.contact,
            page: "contact",
            url: "/contact",
        },
    ];

    const socials = [
        {
            icon: "fa-brands fa-instagram",
            label: "instagram",
            link: "https://www.instagram.com/bracson_/",
        },
        {
            icon: "fa-brands fa-facebook",
            label: "facebook",
            link: "https://m.facebook.com/p/Soci%C3%A9t%C3%A9-Bracson-100094464843099/",
        },
    ];

    const [menuOpened, setMenuOpened] = useState(false);

    useEffect(() => {
        setMenuOpened(false);
    }, [page]);

    const [cookies, setCookie] = useCookies(['user']);

    const changeLanguage = (lang) => {
        setCookie('locale', lang, { path: '/', maxAge: 3600 * 24 * 365 });
        setLocale(lang);
        window.location.reload();
    };

    return (
        <header className={`header ${currentBreakpoint}`}>
            <div className={`header__left ${finished ? "finished" : ""} page--${page}`}>
                <Link to="/"
                    className="header__left__link">
                    <img className="header__left__link__logo"
                        src="https://bracson.b-cdn.net/Logo/logo_circle.png"
                        alt="Bracson" />
                    <h1 className="header__left__link__title">
                        Bracson
                    </h1>
                </Link>
            </div>
            <div className={`header__right ${finished ? "finished" : ""} page--${page}`}>
                {['xl', 'xxl'].includes(currentBreakpoint) &&
                    <React.Fragment>
                        <nav className="header__right__links">
                            {headerLinks.map((link, index) => (
                                <React.Fragment key={index}>
                                    {page === link.page ?
                                        <span key={index}
                                            className="header__right__links__item active">
                                            {link.label}
                                        </span>
                                        : <Link key={index}
                                            to={link.url}
                                            className="header__right__links__item">
                                            {link.label}
                                        </Link>
                                    }
                                </React.Fragment>
                            ))}
                        </nav>
                        <hr className={`header__right__separator ${currentBreakpoint}`} />
                    </React.Fragment>
                }
                {['m', 'l'].includes(currentBreakpoint) &&
                    <React.Fragment>
                        <div className="header__right__links">
                            {page === "contact" ?
                                <span className="header__right__links__item active">
                                    {headerLinks[headerLinks.length - 1].label}
                                </span>
                                : <Link to={headerLinks[headerLinks.length - 1].url}
                                    className="header__right__links__item">
                                    {headerLinks[headerLinks.length - 1].label}
                                </Link>
                            }
                        </div>
                        <hr className={`header__right__separator alt ${currentBreakpoint}`} />
                    </React.Fragment>
                }
                {!['xs'].includes(currentBreakpoint) &&
                    <React.Fragment>
                        <div className="header__right__networks">
                            {socials.map((social, index) => (
                                <a key={index}
                                    href={social.link}
                                    className={`header__right__networks__item ${social.label}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={social.icon} />
                                </a>
                            ))}
                        </div>
                        <button className="header__right__language"
                            onClick={() => changeLanguage(locale === 'fr' ? 'en' : 'fr')}>
                            {locale === 'fr' ?
                                <img className="header__right__language__flag french"
                                    src="https://bracson.b-cdn.net/france.svg.webp"
                                    alt="Drapeau français" />
                                : <img className="header__right__language__flag english"
                                    src="https://bracson.b-cdn.net/uk.webp"
                                    alt="English flag" />
                            }
                        </button>
                        {['s', 'm', 'l'].includes(currentBreakpoint) &&
                            <React.Fragment>
                                <hr className={`header__right__separator ${currentBreakpoint}`} />
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                {['xs', 's', 'm', 'l'].includes(currentBreakpoint) &&
                    <button className={`header__right__button ${menuOpened ? "visible" : ""}`}
                        onClick={() => setMenuOpened(!menuOpened)}>
                        <span className="header__right__button__bar"></span>
                        <span className="header__right__button__bar"></span>
                        <span className="header__right__button__bar"></span>
                    </button>
                }
            </div>
            {['xs', 's', 'm', 'l'].includes(currentBreakpoint) &&
                <div className={`header__menu ${menuOpened ? "visible" : ""}`}>
                    <div className="header__menu__links">
                        {['m', 'l'].includes(currentBreakpoint) ?
                            <React.Fragment>
                                {headerLinks.slice(0, headerLinks.length - 1).map((link, index) => (
                                    <React.Fragment key={index}>
                                        {page === link.page ?
                                            <span key={index}
                                                className="header__right__links__item active">
                                                {link.label}
                                            </span>
                                            : <Link key={index}
                                                to={link.url}
                                                className="header__right__links__item">
                                                {link.label}
                                            </Link>
                                        }
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                            : <React.Fragment>
                                {headerLinks.map((link, index) => (
                                    <React.Fragment key={index}>
                                        {page === link.page ?
                                            <span key={index}
                                                className="header__right__links__item active">
                                                {link.label}
                                            </span>
                                            : <Link key={index}
                                                className="header__right__links__item"
                                                to={link.url}>
                                                {link.label}
                                            </Link>
                                        }
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        }
                        {['xs'].includes(currentBreakpoint) &&
                            <div className="header__menu__bottom">
                                <div className="header__right__networks">
                                    {socials.map((social, index) => (
                                        <a key={index}
                                            href={social.link}
                                            className={`header__right__networks__item ${social.label}`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={social.icon} />
                                        </a>
                                    ))}
                                </div>
                                <button className="header__right__language"
                                    onClick={() => changeLanguage(locale === 'fr' ? 'en' : 'fr')}>
                                    {locale === 'fr' ?
                                        <React.Fragment>
                                            <img className="header__right__language__flag french"
                                                src="https://bracson.b-cdn.net/france.svg.webp"
                                                alt="Drapeau français" />
                                            Français
                                        </React.Fragment>
                                        : <React.Fragment>
                                            <img className="header__right__language__flag english"
                                                src="https://bracson.b-cdn.net/uk.webp"
                                                alt="English flag" />
                                            English
                                        </React.Fragment>
                                    }
                                </button>
                            </div>
                        }
                    </div>
                    <div className="header__menu__background"
                        onClick={() => setMenuOpened(!menuOpened)}></div>
                </div>
            }
        </header>
    )
}