import './styles/styles.scss';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { useCookies } from 'react-cookie';
import { initializeAnalytics } from './utils/firebase';
import Home from './components/Home';
import CookieBanner from './components/CookieBanner';
import Header from './components/Header';
import Footer from './components/Footer';
import Universes from './components/Universes';
import UniverseImage5 from './assets/Activities.jpg';
import Service from './components/Service';
import PageNotFound from './components/PageNotFound';
import Contact from './components/Contact';
import Shop from './components/Shop';
import Legal from './components/Legal';
import Terms from './components/Terms';
import Cookies from './components/Cookies';
import Breadcrumb from './components/Breadcrumb';

export const Locale = React.createContext(null);
export const Title = React.createContext(null);
export const Window = React.createContext(null);
export const Content = React.createContext(null);

function App() {
  const [cookies] = useCookies(['analyticsCookies']);

  useEffect(() => {
    if (cookies.analyticsCookies) {
      initializeAnalytics();
    }
  }, [cookies.analyticsCookies]);

  const [locale, setLocale] = useState(() => {
    return cookies.locale || 'fr';
  });
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('');
  const [page, setPage] = useState("home");
  const [ancestors, setAncestors] = useState([]);
  const [finished, setFinished] = useState(false);

  const translations = {
    "fr": {
      cookies: {
        title: "Consentement aux cookies",
        banner: "Ce site utilise des cookies et vous donne le contrôle sur ceux que vous souhaitez activer.",
        disclaimer_choice: "Vous pouvez choisir d'accepter tous les cookies ou seulement ceux nécessaires. Vous pourrez modifier ce choix ultérieurement en cliquant sur l'icône de cookies en bas à gauche de la page.",
        accept_all: "Tout accepter",
        personalize: "Personnaliser",
        accept: "Accepter",
        reject: "Refuser",
        confirm: "Confirmer",
        return: "Réduire les détails",
        close: "Fermer",
      },
      intro: {
        access: "Accéder au site",
      },
      header: {
        home: "Accueil",
        bicyclette: "Bicyclette Smoothie Énergie",
        universe: "Nos univers",
        shop: "Boutique",
        contact: "Nous contacter",
      },
      home: {
        life_is_a_party: {
          title: "La vie est une fête, partageons-la !",
          meta_description: "Nous transformons vos événements en moments inoubliables. Mariages, anniversaires, soirées d'entreprise ou fêtes spéciales, nous avons tout ce qu'il faut pour créer une ambiance unique. Des machines à mousse, bulles et canons à confettis, à la Bicyclette Smoothie Énergie, en passant par des DJ sets vibrants, nous vous accompagnons pour que votre événement reste gravé dans les mémoires. Explorez nos univers juste en dessous et rejoignez-nous sur nos réseaux sociaux !",
          text: "Nous transformons vos événements en <span>moments inoubliables</span>. Mariages, anniversaires, soirées d'entreprise ou fêtes spéciales, nous avons tout ce qu'il faut pour créer <span>une ambiance unique</span>. Des machines à mousse, bulles et canons à confettis, à la Bicyclette Smoothie Énergie, en passant par des DJ sets vibrants, nous vous accompagnons pour que <span>votre événement</span> reste gravé <span>dans les mémoires</span>. Explorez nos univers juste en dessous et rejoignez-nous sur <span>nos réseaux sociaux</span>&nbsp;!",
          button: "En savoir plus sur nous",
        },
        our_universes: {
          title: "Nos univers",
          explore_all: "Tout explorer",
          button: "Plus de détails",
          items: {
            titles: [
              "Bicyclette Smoothie Énergie",
              "Machines à bulles",
              "Machines à mousse",
              "Canons à confettis",
              "Terrain de pétanque éphémère",
              "DJ",
              "Bracelets connectés lumineux",
              "Flammes",
            ],
            texts: [
              `Découvrez une <span>expérience unique</span> avec notre <span>Bicyclette Smoothie Énergie</span>, où l’effort physique se transforme en <span>énergie utile</span>&nbsp;! En pédalant, vous <span>rechargez votre téléphone</span> et activez en même temps une <span>machine à smoothie</span> pour préparer une boisson <span>rafraîchissante</span> et <span>savoureuse</span>. Ce concept <span>amusant et écologique</span> est parfait pour ajouter une <span>touche d’originalité</span> à vos événements. Que ce soit pour un <span>challenge entre amis</span>, <span>collègues</span> ou simplement pour profiter d’un <span>smoothie fait maison</span>, tout en faisant un geste pour la planète, notre <span>Bicyclette Smoothie Énergie</span> garantit une <span>expérience mémorable</span> pour tous les participants. Pas de panique, <span>"Pédalez, on s’occupe du reste"</span> – vous n’avez qu’à <span>profiter du moment</span> et de <span>vos smoothies</span>&nbsp;!`,
              "Ajoutez une <span>touche de magie</span> à vos événements avec nos <span>machines à bulles</span>&nbsp;! Rien ne crée une <span>ambiance féerique</span> comme une <span>pluie de bulles</span> qui flottent doucement dans l’air, captivant <span>petits</span> et <span>grands</span>. Nos <span>machines à bulles</span> sont idéales pour <span>tous types d’occasions</span>&nbsp;: <span>mariages</span>, <span>anniversaires</span>, <span>festivals</span>, ou <span>fêtes en plein air</span>. Simples à utiliser et <span>spectaculaires</span>, elles apportent une <span>dimension ludique</span> et <span>poétique</span> à vos moments forts. Laissez la <span>magie des bulles</span> opérer et <span>transformez votre événement</span> en un <span>souvenir inoubliable</span>, où <span>éclats de rire</span> et <span>émerveillement</span> se mêlent à chaque <span>bulle</span> qui s'envole.",
              "Plongez dans une ambiance <span>festive</span> et <span>ludique</span> avec nos <span>machines à mousse</span>&nbsp;! Parfaites pour tous vos événements, elles créent une <span>mer de mousse légère</span> et <span>délicate</span> qui incite à s’amuser et à danser. Que ce soit pour des <span>soirées dansantes</span>, des <span>anniversaires</span>, ou des <span>événements en plein air</span>, nos machines à mousse sont le <span>complément idéal</span> pour une <span>expérience inoubliable</span>. Offrez à vos invités une véritable immersion dans un univers de <span>joie</span> et de <span>convivialité</span>, où chaque éclat de rire et chaque moment partagé sont accentués par des <span>vagues de mousse</span> qui rendent l'atmosphère encore plus <span>festive</span>. Laissez-vous emporter par l’effervescence de la mousse et créez des <span>souvenirs mémorables</span>&nbsp;!",
              "Ajoutez une <span>explosion de joie</span> à vos événements avec nos <span>canons à confettis</span>&nbsp;! Que ce soit pour un <span>mariage</span>, un <span>anniversaire</span>, un <span>lancement de produit</span> ou toute autre célébration, nos canons à confettis transforment chaque moment en une <span>fête mémorable</span>. Faciles à utiliser et <span>spectaculaires</span> à voir, ils libèrent une <span>pluie de confettis colorés</span> qui émerveille vos invités et crée une <span>ambiance festive instantanée</span>. Imaginez les sourires et les éclats de rire lorsque les confettis s’envolent dans les airs, apportant une touche d’éclat et de magie à vos souvenirs. Laissez nos canons à confettis rendre vos événements <span>inoubliables</span>, où chaque instant est célébré avec <span>style</span> et <span>enthousiasme</span>&nbsp;!",
              "Apportez une touche de <span>convivialité</span> à vos événements avec notre <span>terrain de pétanque éphémère</span>&nbsp;! Idéal pour créer des moments de <span>détente</span> et de <span>partage</span>, ce terrain invite vos invités à se retrouver autour d'une <span>partie amicale</span>. Que ce soit lors d'un mariage, d'un anniversaire, ou d'une <span>fête d'entreprise</span>, la pétanque est un excellent moyen d'encourager l'<span>interaction</span> et de <span>briser la glace</span>. Facile à installer et à démonter, notre terrain de pétanque éphémère s’adapte à tous les types d’espaces, qu’ils soient en intérieur ou en extérieur. Offrez à vos convives la chance de s’amuser tout en profitant d'un bon moment ensemble, où chaque lancer de boule crée des <span>souvenirs chaleureux</span> et <span>inoubliables</span>.",
              "Faites vibrer votre événement avec nos <span>DJ sets</span> énergiques et <span>mémorables</span>&nbsp;! Que ce soit pour un mariage, une <span>soirée d'entreprise</span>, un anniversaire ou une fête entre amis, nos DJs professionnels savent créer l’ambiance parfaite pour faire danser vos invités. Avec une <span>sélection musicale variée</span> allant des <span>classiques intemporels</span> aux <span>hits modernes</span>, chaque set est personnalisé pour s'adapter à l'énergie de votre événement. Attendez-vous à des <span>mixes envoûtants</span> qui rythmeront votre soirée, tout en assurant une <span>expérience inoubliable</span>. Offrez à vos convives une ambiance <span>festive</span> et <span>dynamique</span>, où la musique et la danse se mêlent pour créer des <span>souvenirs exceptionnels</span>.",
              "Illuminez vos événements avec nos <span>bracelets connectés lumineux</span>&nbsp;! Ces <span>accessoires innovants</span> ajoutent une touche d’éclat et de fun à toutes vos célébrations, qu’il s’agisse de <span>mariages</span>, d’<span>anniversaires</span> ou de <span>soirées en entreprise</span>. Grâce à leurs <span>lumières multicolores</span> et à leur <span>design élégant</span>, nos bracelets s’adaptent à tous les styles et créent une <span>ambiance festive inoubliable</span>. Faciles à utiliser, ils se synchronisent parfaitement avec la musique, transformant chaque instant en un véritable <span>spectacle lumineux</span>. Offrez à vos invités une <span>expérience unique et mémorable</span>, où chaque bracelet scintille comme une étoile, illuminant les sourires et les rires tout au long de la soirée. Laissez la magie des lumières opérer et faites de votre événement un <span>moment exceptionnel</span>&nbsp;!",
              "Éveillez vos sens et transformez vos événements en véritables <span>spectacles</span> avec nos <span>flammes dansantes</span>&nbsp;! Parfaites pour ajouter une ambiance <span>spectaculaire</span> et <span>chaleureuse</span>, ces flammes créent une atmosphère <span>captivante</span> qui émerveille vos invités. Que ce soit pour un mariage, une soirée d'entreprise, ou un <span>festival en plein air</span>, nos installations de flammes apportent une touche de <span>magie</span> et de <span>fascination</span> à chaque moment. En plus d’être <span>visuellement impressionnantes</span>, elles créent une ambiance <span>conviviale</span> qui incite à la <span>célébration</span>. Laissez nos flammes illuminer votre événement et offrez à vos convives une <span>expérience inoubliable</span>, où chaleur et éclat se mêlent pour des <span>souvenirs mémorables</span>."
            ],
            meta_description: [
              `Découvrez une expérience unique avec notre vélo smoothie énergie, où l’effort physique se transforme en énergie utile ! En pédalant, vous rechargez votre téléphone et activez en même temps une machine à smoothie pour préparer une boisson rafraîchissante et savoureuse. Ce concept amusant et écologique est parfait pour ajouter une touche d’originalité à vos événements. Que ce soit pour un challenge entre amis ou simplement pour profiter d’un smoothie fait maison, tout en faisant un geste pour la planète, notre vélo smoothie énergie garantit une expérience mémorable pour tous les participants. Pas de panique, "Pédalez, on s’occupe du reste" – vous n’avez qu’à profiter du moment et de vos smoothies !`,
              "Ajoutez une touche de magie à vos événements avec nos machines à bulles ! Rien ne crée une ambiance féerique comme une pluie de bulles qui flottent doucement dans l’air, captivant petits et grands. Nos machines à bulles sont idéales pour tous types d’occasions : mariages, anniversaires, festivals, ou fêtes en plein air. Simples à utiliser et spectaculaires, elles apportent une dimension ludique et poétique à vos moments forts. Laissez la magie des bulles opérer et transformez votre événement en un souvenir inoubliable, où éclats de rire et émerveillement se mêlent à chaque bulle qui s'envole.",
              "Plongez dans une ambiance festive et ludique avec nos machines à mousse ! Parfaites pour tous vos événements, elles créent une mer de mousse légère et délicate qui incite à s’amuser et à danser. Que ce soit pour des soirées dansantes, des anniversaires, ou des événements en plein air, nos machines à mousse sont le complément idéal pour une expérience inoubliable. Offrez à vos invités une véritable immersion dans un univers de joie et de convivialité, où chaque éclat de rire et chaque moment partagé sont accentués par des vagues de mousse qui rendent l'atmosphère encore plus festive. Laissez-vous emporter par l’effervescence de la mousse et créez des souvenirs mémorables !",
              "Ajoutez une explosion de joie à vos événements avec nos canons à confettis ! Que ce soit pour un mariage, un anniversaire, un lancement de produit ou toute autre célébration, nos canons à confettis transforment chaque moment en une fête mémorable. Faciles à utiliser et spectaculaires à voir, ils libèrent une pluie de confettis colorés qui émerveille vos invités et crée une ambiance festive instantanée. Imaginez les sourires et les éclats de rire lorsque les confettis s’envolent dans les airs, apportant une touche d’éclat et de magie à vos souvenirs. Laissez nos canons à confettis rendre vos événements inoubliables, où chaque instant est célébré avec style et enthousiasme !",
              "Apportez une touche de convivialité à vos événements avec notre terrain de pétanque éphémère ! Idéal pour créer des moments de détente et de partage, ce terrain invite vos invités à se retrouver autour d'une partie amicale. Que ce soit lors d'un mariage, d'un anniversaire, ou d'une fête d'entreprise, la pétanque est un excellent moyen d'encourager l'interaction et de briser la glace. Facile à installer et à démonter, notre terrain de pétanque éphémère s’adapte à tous les types d’espaces, qu’ils soient en intérieur ou en extérieur. Offrez à vos convives la chance de s’amuser tout en profitant d'un bon moment ensemble, où chaque lancer de boule crée des souvenirs chaleureux et inoubliables.",
              "Faites vibrer votre événement avec nos DJ sets énergiques et mémorables ! Que ce soit pour un mariage, une soirée d'entreprise, un anniversaire ou une fête entre amis, nos DJs professionnels savent créer l’ambiance parfaite pour faire danser vos invités. Avec une sélection musicale variée allant des classiques intemporels aux hits modernes, chaque set est personnalisé pour s'adapter à l'énergie de votre événement. Attendez-vous à des mixes envoûtants qui rythmeront votre soirée, tout en assurant une expérience inoubliable. Offrez à vos convives une ambiance festive et dynamique, où la musique et la danse se mêlent pour créer des souvenirs exceptionnels.",
              "Illuminez vos événements avec nos bracelets connectés lumineux ! Ces accessoires innovants ajoutent une touche d’éclat et de fun à toutes vos célébrations, qu’il s’agisse de mariages, d’anniversaires ou de soirées en entreprise. Grâce à leurs lumières multicolores et à leur design élégant, nos bracelets s’adaptent à tous les styles et créent une ambiance festive inoubliable. Faciles à utiliser, ils se synchronisent parfaitement avec la musique, transformant chaque instant en un véritable spectacle lumineux. Offrez à vos invités une expérience unique et mémorable, où chaque bracelet scintille comme une étoile, illuminant les sourires et les rires tout au long de la soirée. Laissez la magie des lumières opérer et faites de votre événement un moment exceptionnel !",
              "Éveillez vos sens et transformez vos événements en véritables spectacles avec nos flammes dansantes ! Parfaites pour ajouter une ambiance spectaculaire et chaleureuse, ces flammes créent une atmosphère captivante qui émerveille vos invités. Que ce soit pour un mariage, une soirée d'entreprise, ou un festival en plein air, nos installations de flammes apportent une touche de magie et de fascination à chaque moment. En plus d’être visuellement impressionnantes, elles créent une ambiance conviviale qui incite à la célébration. Laissez nos flammes illuminer votre événement et offrez à vos convives une expérience inoubliable, où chaleur et éclat se mêlent pour des souvenirs mémorables.",
            ],
          },
        },
        gallery: {
          title: "Galerie photos",
          display_more: "En afficher plus",
          display_less: "En afficher moins",
        },
        they_trusted_us: {
          title: "Ils nous ont fait confiance",
        },
        video: {
          title: "Vidéo de présentation",
        },
      },
      bicyclette: {
        title: "Bicyclette Smoothie Énergie",
        introduction: {
          title: "Pédalez, on s'occupe du reste",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. <span>Etiam sit amet</span> ex eget dolor semper laoreet quis eu nunc. <span>Sed pulvinar</span> sapien lorem, sed iaculis lectus commodo a. Duis et condimentum urna, id facilisis nisl. Ut vehicula dolor metus. Interdum et malesuada <span>fames ac ante</span> ipsum primis in faucibus. Curabitur blandit nibh neque, eget lacinia arcu rhoncus dapibus. Pellentesque ut mi id velit <span>efficitur tempor</span>.",
        },
        gallery: {
          title: "Quelques images qui vous donneront soif...",
        }
      },
      universes: {
        meta_description: "Découvrez les services sur mesure de Bracson pour rendre vos événements uniques : canons à mousse, confettis, bulles, Bicyclette Smoothie Énergie, DJ sets et bien plus. Parfaits pour mariages, anniversaires et soirées d'entreprise, nos prestations s'adaptent à toutes vos envies.",
        title: "Nos univers",
        search: "Rechercher à l'aide de termes...",
        no_result_found: "Aucun résultat trouvé.",
        new: "Nouveau",
      },
      shop: {
        meta_description: "Découvrez notre boutique en ligne proposant des sweats, des t-shirts, des casquettes, des gobelets, des éventails et des débardeurs. Des articles tendances et stylés pour toutes les occasions, avec des options pour chaque saison.",
        text: "Les produits présentés <span>ne sont pas disponibles à l'achat en ligne</span>. Toutes les <span>informations</span>, y compris <span>les prix</span>, sont fournies <span>à titre indicatif</span> uniquement. Si vous souhaitez <span>obtenir un produit</span> ou pour toute question à leur sujet, n'hésitez pas à <span>nous contacter</span> via le <span>formulaire de contact</span> ou sur <span>nos réseaux sociaux</span>.",
        products: "Produits",
        one_size: "Taille unique",
      },
      contact: {
        meta_description: "Contactez Bracson pour transformer vos événements en moments inoubliables. Que ce soit pour des mariages, anniversaires ou soirées d'entreprise, nos services sur mesure comme les canons à mousse, confettis, bulles, Bicyclette Smoothie Énergie et DJ sets feront de votre fête une réussite.",
        label: "Contact",
        title: "Contactez-nous&nbsp;!",
        disclaimer: "Tous les champs sont <span>requis</span>.<br />Votre adresse email doit être <span>réelle</span>.",
        form: {
          name: {
            label: "Nom complet",
            placeholder: "Jean Dupont",
          },
          email: {
            label: "Adresse email",
            placeholder: "jean.dupont@exemple.com",
          },
          subjects: {
            label: "Sujet",
            general: "Demande générale",
            shop: "Boutique",
            service: "Prestation",
          },
          message: {
            label: "Message",
            placeholder: "Commencez à écrire ici...",
          },
          submit: "Envoyer",
        },
        captcha_error: "Avant de poursuivre, veuillez cocher la case du CAPTCHA.",
        error: "Une erreur est survenue lors de l'envoi du message.<br /><br />Veuillez réessayer plus tard ou me contacter directement via les coordonnées disponibles ci-dessous.",
        success: "Merci pour votre message&nbsp;!<br />Nous vous répondrons dans les plus brefs délais.<br /><br />Si votre adresse email est correcte, vous avez du recevoir un accusé de réception de ce message. Veuillez également vérifier dans vos spams si ce n'est pas le cas. Si vous n'avez toujours pas reçu d'accusé de réception d'ici 1h, n'hésitez pas à nous contacter via les coordonnées ci-dessous.",
        details: "Coordonnées",
        loading: "Merci de patienter durant l'envoi, cela ne devrait prendre que quelques secondes...",
        cookies: {
          label: "Cookies",
          disclaimer: "Ce formulaire est protégé par <span>reCAPTCHA</span> pour empêcher le spam. Cependant, vous avez refusé les <span>cookies nécessaires</span> à son fonctionnement.<br />Vous pouvez modifier vos <span>préférences en matière de cookies</span> en cliquant sur l'icône de cookies présente en <span>bas à droite</span>."
        },
        mail: {
          subject: "Accusé de réception de votre message",
          body: "Nous vous remercions d’avoir pris le temps de nous contacter. Nous avons bien reçu votre message et nous vous répondrons dans les plus brefs délais.<br /><br />En attendant, si vous avez des questions supplémentaires ou si vous souhaitez modifier votre demande, n’hésitez pas à nous répondre directement à cet e-mail.<br /><br />Cordialement,<br />L'équipe Bracson",
        },
      },
      legal: {
        meta_description: "Consultez les mentions légales de Bracson pour comprendre nos engagements et responsabilités en matière de protection des données, de propriété intellectuelle et de réglementation. Transparence et conformité pour une expérience sereine.",
        site_information: {
          title: "Éditeur du site",
          responsable: "Responsable",
          address: "Adresse",
          email_address: "Adresse email",
          phone_number: "Téléphone",
          siret: "SIRET",
          vat_number: "Numéro de TVA",
        },
        hosting_provider: {
          title: "Hébergement",
          text: "Le site est hébergé par&nbsp;:",
          text_2: "Les photos et vidéos du site sont hébergées par&nbsp;:",
          hosting_provider: "Hébergeur",
          stockage: "Service de stockage",
          address: "Adresse",
          phone_number: "Téléphone",
        },
        intellectual_property: {
          title: "Propriété intellectuelle",
        },
        credits: {
          title: "Crédits",
          design_development: "Design et développement",
          photos_and_videos: "Photos et vidéos",
        },
        disclaimer: {
          title: "Version de référence",
          text: "En cas de divergence entre les versions traduites du présent document, la version française prévaut.",
        },
      },
      terms: {
        meta_description: "Découvrez les Conditions Générales d'Utilisation de Bracson, qui régissent l'accès et l'utilisation de nos services. Informez-vous sur vos droits et obligations lors de la réservation de nos prestations pour des événements inoubliables.",
        introduction: {
          title: "Introduction",
          text: "Les présentes Conditions Générales d'Utilisation (CGU) ont pour objet de définir les modalités de mise à disposition des services du site Bracson et les conditions d’utilisation du site par l’utilisateur.",
        },
        access: {
          title: "Accès au site",
          text: "Le site Bracson est accessible gratuitement à tout utilisateur disposant d’un accès à Internet. Tous les coûts relatifs à l'accès au site, qu'ils soient matériels, logiciels ou d'accès à Internet, sont à la charge de l'utilisateur.",
        },
        responsibility: {
          title: "Responsabilité",
          text: "Bracson met tout en œuvre pour fournir des informations exactes et à jour. Toutefois, nous ne pouvons garantir l’exactitude, la complétude ou l’actualité des informations présentes sur le site. La responsabilité de Bracson ne saurait être engagée en cas de dommages liés à l’utilisation du site.",
        },
        intellectual_property: {
          title: "Propriété intellectuelle",
          text: "Tous les contenus présents sur le site Bracson, incluant, de manière non exhaustive, les textes, images, vidéos, logos et icônes, sont la propriété de Bracson ou de leurs auteurs respectifs. Toute reproduction, distribution ou utilisation de ces contenus est interdite sans autorisation préalable.",
        },
        personal_data: {
          title: "Collecte de données personnelles",
          text: "Les informations collectées via le site Bracson sont utilisées exclusivement pour traiter vos demandes et améliorer nos services. Conformément au RGPD, vous disposez d’un droit d’accès, de rectification et de suppression de vos données personnelles.",
        },
        cookies: {
          title: "Cookies",
        },
        external_links: {
          title: "Liens externes",
          text: "Le site Bracson peut contenir des liens vers d'autres sites internet. Bracson n'est pas responsable du contenu de ces sites externes ni des conséquences de leur utilisation.",
        },
        modification: {
          title: "Modification des CGU",
          text: "Bracson se réserve le droit de modifier à tout moment les présentes CGU. Il est recommandé de consulter cette page régulièrement pour être informé des modifications.",
        },
        law: {
          title: "Loi applicable et juridiction",
          text: "Les présentes CGU sont régies par la loi française. En cas de litige, les tribunaux français seront seuls compétents.",
        },
      },
      cookie: {
        meta_description: "Découvrez la politique des cookies de Bracson, qui explique comment nous utilisons les cookies pour améliorer votre expérience en ligne. Informez-vous sur les types de cookies que nous utilisons et comment gérer vos préférences.",
        definition: {
          title: "Qu'est-ce qu'un cookie&nbsp;?",
          text: "Un cookie est un petit fichier texte déposé sur votre terminal (ordinateur, tablette, smartphone, etc.) par les sites web que vous visitez. Ils sont utilisés pour faire fonctionner les sites internet, améliorer votre expérience utilisateur et fournir certaines informations aux propriétaires des sites.",
        },
        type: {
          title: "Types de cookies utilisés sur notre site",
          text: "Nous utilisons différents types de cookies sur notre site&nbsp;:",
          title_1: "Cookies strictement nécessaires",
          text_1: "Ces cookies sont indispensables au bon fonctionnement du site. Sans ces cookies, certaines fonctionnalités (comme l'accès à des zones sécurisées) ne peuvent pas être fournies. Google reCAPTCHA fait partie de ce type de cookies&nbsp;; si vous décidez de refuser les cookies non essentiels, vous n'aurez pas accès au formulaire de contact afin d'éviter les potentiels spams.",
          title_2: "Cookies de performance et d'analyse",
          text_2: "Ces cookies collectent des informations sur la façon dont les visiteurs utilisent notre site, comme les pages les plus visitées ou les erreurs rencontrées. Ces données nous aident à améliorer le fonctionnement du site et l’expérience utilisateur. Par exemple, nous utilisons Google Analytics pour analyser le trafic du site.",
          title_3: "Cookies de fonctionnalité",
          text_3: "Ces cookies permettent de mémoriser vos préférences (comme la langue sélectionnée) afin de vous offrir une expérience personnalisée.",
        },
        gestion: {
          title: "Gestion des cookies",
          text_2: "La plupart des navigateurs vous permettent de contrôler les cookies à travers leurs paramètres. Vous pouvez ainsi choisir de bloquer ou de supprimer certains cookies. Voici les liens vers la documentation de gestion des cookies pour les navigateurs les plus populaires&nbsp;:",
        },
        conservation: {
          title: "Durée de conservation des cookies",
          text: "Les cookies ont une durée de vie limitée qui varie selon leur type. Les cookies de session sont supprimés lorsque vous fermez votre navigateur, tandis que les cookies persistants restent sur votre terminal jusqu'à leur expiration (1 an) ou suppression manuelle.",
        },
        droit: {
          title: "Droit de l'utilisateur",
          text: "Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez du droit de retirer à tout moment votre consentement concernant l'utilisation des cookies non essentiels. Vous pouvez également exercer vos droits d'accès, de rectification ou de suppression des données vous concernant en nous contactant à l'adresse suivante&nbsp;: bracson.contact[a]gmail.com",
        },
        modification: {
          title: "Modifications de la politique de cookies",
          text: "Nous nous réservons le droit de modifier cette politique de cookies à tout moment afin de refléter les changements dans nos pratiques ou les évolutions législatives. Nous vous encourageons à consulter régulièrement cette page pour rester informé des mises à jour.",
        },
        actual_choice: " (Choix actuel)",
      },
      footer: {
        legal_notice: "Mentions légales",
        all_rights_reserved: "Tous droits réservés.",
        sitemap: "Plan du site",
        useful_links: "Liens utiles",
        terms: "Conditions générales d'utilisation",
        cookie: "Politique de cookies",
        powered_by: "Propulsé par",
      },
      not_found: {
        meta_description: "Oups ! La page que vous cherchez sur Bracson n'est pas disponible. Explorez nos services uniques pour vos événements ou retournez à la page d'accueil pour découvrir comment nous pouvons rendre vos occasions inoubliables.",
        label: "404 - Page inexistante",
        title: "404",
        text: "Aïe, on dirait que vous êtes perdu...<br />Mais peut-être qu'on peut vous aider à <span>vous y retrouver&nbsp;?</span>",
      },
    },
    "en": {
      cookies: {
        title: "Cookie Consent",
        banner: "This site uses cookies and gives you control over which ones you want to activate.",
        disclaimer_choice: "You can choose to accept all cookies or only the necessary ones. You can modify this choice later by clicking on the cookie icon at the bottom left of the page.",
        accept_all: "Accept all",
        personalize: "Personalize",
        accept: "Accept",
        reject: "Reject",
        confirm: "Confirm",
        return: "Reduce details",
        close: "Close",
      },
      intro: {
        access: "Access to the site",
      },
      header: {
        home: "Home",
        bicyclette: "Bicyclette Smoothie Énergie",
        universe: "Our Universes",
        shop: "Shop",
        contact: "Contact Us",
      },
      home: {
        life_is_a_party: {
          title: "Life is a party, let's share it!",
          meta_description: "We turn your events into unforgettable moments. Whether it’s weddings, birthdays, corporate parties, or special celebrations, we have everything you need to create a unique atmosphere. From foam, bubble machines and confettis cannons, to the Bicyclette Smoothie Énergie and vibrant DJ sets, we’re here to ensure your event is one to remember. Explore our universes just below and join us on our social media!",
          text: "We turn your events into <span>unforgettable moments</span>. Whether it’s weddings, birthdays, corporate parties, or special celebrations, we have everything you need to create <span>a unique atmosphere</span>. From foam, bubble machines and confettis cannons, to the Bicyclette Smoothie Énergie and vibrant DJ sets, we’re here to ensure <span>your event</span> is one <span>to remember</span>. Explore our universes just below and join us on <span>our social media</span>!",
          button: "More about us",
        },
        our_universes: {
          title: "Our Universes",
          explore_all: "Explore all",
          button: "More details",
          items: {
            titles: [
              "Bicyclette Smoothie Énergie",
              "Bubble Machines",
              "Foam Machines",
              "Confetti Cannons",
              "Temporary Petanque Court",
              "DJ",
              "Illuminated Smart Bracelets",
              "Flames",
            ], texts: [
              `Discover a <span>unique experience</span> with our <span>Smoothie Energy Bicycle</span>, where physical effort transforms into <span>useful energy</span>&nbsp;! By pedaling, you <span>charge your phone</span> and simultaneously activate a <span>s smoothie machine</span> to prepare a <span>refreshing</span> and <span>delicious</span> drink. This <span>fun and eco-friendly</span> concept is perfect for adding a <span>touch of originality</span> to your events. Whether it's for a <span>challenge among friends</span>, <span>colleagues</span>, or simply to enjoy a <span>homemade smoothie</span>, while making a gesture for the planet, our <span>Smoothie Energy Bicycle</span> guarantees a <span>memorable experience</span> for all participants. No worries, <span>"Pedal, we’ll take care of the rest"</span> – you just need to <span>enjoy the moment</span> and your <span>smoothies</span>&nbsp;!`,
              "Add a <span>touch of magic</span> to your events with our <span>bubble machines</span>&nbsp;! Nothing creates a <span>fairy-tale atmosphere</span> like a <span>shower of bubbles</span> floating gently in the air, captivating <span>young</span> and <span>old</span>. Our <span>bubble machines</span> are ideal for <span>all types of occasions</span>&nbsp;: <span>weddings</span>, <span>birthdays</span>, <span>festivals</span>, or <span>outdoor parties</span>. Easy to use and <span>spectacular</span>, they bring a <span>playful</span> and <span>poetic dimension</span> to your key moments. Let the <span>magic of bubbles</span> work and <span>transform your event</span> into an <span>unforgettable memory</span>, where <span>laughter</span> and <span>wonder</span> intertwine with every <span>bubble</span> that flies away.",
              "Dive into a <span>festive</span> and <span>playful</span> atmosphere with our <span>foam machines</span>&nbsp;! Perfect for all your events, they create a <span>sea of light</span> and <span>delicate foam</span> that encourages fun and dancing. Whether for <span>dancing parties</span>, <span>birthdays</span>, or <span>outdoor events</span>, our foam machines are the <span>ideal complement</span> for an <span>unforgettable experience</span>. Give your guests a true immersion in a world of <span>joy</span> and <span>friendliness</span>, where every burst of laughter and every shared moment are accentuated by <span>waves of foam</span> that make the atmosphere even more <span>festive</span>. Let yourself be carried away by the effervescence of the foam and create <span>memorable memories</span>&nbsp;!",
              "Add an <span>explosion of joy</span> to your events with our <span>confetti cannons</span>&nbsp;! Whether it's for a <span>wedding</span>, a <span>birthday</span>, a <span>product launch</span>, or any other celebration, our confetti cannons turn every moment into a <span>memorable party</span>. Easy to use and <span>spectacular</span> to watch, they release a <span>shower of colorful confetti</span> that dazzles your guests and creates an <span>instant festive atmosphere</span>. Imagine the smiles and laughter as the confetti flies through the air, adding a touch of sparkle and magic to your memories. Let our confetti cannons make your events <span>unforgettable</span>, where every moment is celebrated with <span>style</span> and <span>enthusiasm</span>&nbsp;!",
              "Bring a <span>friendly touch</span> to your events with our <span>pop-up pétanque court</span>&nbsp;! Ideal for creating moments of <span>relaxation</span> and <span>sharing</span>, this court invites your guests to gather around a <span>friendly game</span>. Whether during a wedding, a birthday, or a <span>corporate party</span>, pétanque is a great way to encourage <span>interaction</span> and <span>break the ice</span>. Easy to set up and take down, our pop-up pétanque court adapts to all types of spaces, whether indoors or outdoors. Give your guests the chance to have fun while enjoying good moments together, where each throw of the ball creates <span>warm</span> and <span>unforgettable memories</span>.",
              "Make your event resonate with our energetic and <span>memorable DJ sets</span>&nbsp;! Whether for a wedding, a <span>corporate party</span>, a birthday, or a gathering with friends, our professional DJs know how to create the perfect atmosphere to get your guests dancing. With a <span>varied musical selection</span> ranging from <span>timeless classics</span> to <span>modern hits</span>, each set is personalized to match the energy of your event. Expect <span>captivating mixes</span> that will rhythm your evening while ensuring an <span>unforgettable experience</span>. Offer your guests a <span>festive</span> and <span>dynamically engaging atmosphere</span>, where music and dance combine to create <span>exceptional memories</span>.",
              "Light up your events with our <span>illuminated smart bracelets</span>! These <span>innovative accessories</span> add a touch of sparkle and fun to all your celebrations, whether it’s a <span>wedding</span>, <span>birthday</span>, or <span>corporate party</span>. With their <span>multicolored lights</span> and <span>sleek design</span>, our bracelets fit all styles and create an <span>unforgettable festive atmosphere</span>. Easy to use, they perfectly synchronize with the music, turning every moment into a true <span>light show</span>. Give your guests a <span>unique and memorable experience</span>, where each bracelet sparkles like a star, illuminating smiles and laughter throughout the evening. Let the magic of lights work and make your event a <span>remarkable moment</span>!",
              "Awaken your senses and transform your events into true <span>spectacles</span> with our <span>dancing flames</span>&nbsp;! Perfect for adding a <span>spectacular</span> and <span>warm</span> atmosphere, these flames create a <span>captivating environment</span> that amazes your guests. Whether for a wedding, a corporate evening, or an <span>outdoor festival</span>, our flame installations bring a touch of <span>magic</span> and <span>fascination</span> to every moment. In addition to being <span>visually stunning</span>, they create a <span>friendly atmosphere</span> that encourages <span>celebration</span>. Let our flames illuminate your event and offer your guests an <span>unforgettable experience</span>, where warmth and brilliance combine for <span>memorable memories</span>."
            ],
            meta_description: [
              `Discover a unique experience with our Smoothie Energy Bicycle, where physical effort transforms into useful energy! By pedaling, you charge your phone and simultaneously activate a smoothie machine to prepare a refreshing and delicious drink. This fun and eco-friendly concept is perfect for adding a touch of originality to your events. Whether it's for a challenge among friends or simply to enjoy a homemade smoothie while making a gesture for the planet, our Smoothie Energy Bicycle guarantees a memorable experience for all participants. No worries, "Pedal, we’ll take care of the rest" – you just need to enjoy the moment and your smoothies!`,
              "Add a touch of magic to your events with our bubble machines! Nothing creates a fairy-tale atmosphere like a shower of bubbles floating gently in the air, captivating young and old. Our bubble machines are ideal for all types of occasions: weddings, birthdays, festivals, or outdoor parties. Easy to use and spectacular, they bring a playful and poetic dimension to your key moments. Let the magic of bubbles work and transform your event into an unforgettable memory, where laughter and wonder intertwine with every bubble that flies away.",
              "Dive into a festive and playful atmosphere with our foam machines! Perfect for all your events, they create a sea of light and delicate foam that encourages fun and dancing. Whether for dancing parties, birthdays, or outdoor events, our foam machines are the ideal complement for an unforgettable experience. Give your guests a true immersion in a world of joy and friendliness, where every burst of laughter and every shared moment are accentuated by waves of foam that make the atmosphere even more festive. Let yourself be carried away by the effervescence of the foam and create memorable memories!",
              "Add an explosion of joy to your events with our confetti cannons! Whether it's for a wedding, a birthday, a product launch, or any other celebration, our confetti cannons turn every moment into a memorable party. Easy to use and spectacular to watch, they release a shower of colorful confetti that dazzles your guests and creates an instant festive atmosphere. Imagine the smiles and laughter as the confetti flies through the air, adding a touch of sparkle and magic to your memories. Let our confetti cannons make your events unforgettable, where every moment is celebrated with style and enthusiasm!",
              "Bring a friendly touch to your events with our pop-up pétanque court! Ideal for creating moments of relaxation and sharing, this court invites your guests to gather around a friendly game. Whether during a wedding, a birthday, or a corporate party, pétanque is a great way to encourage interaction and break the ice. Easy to set up and take down, our pop-up pétanque court adapts to all types of spaces, whether indoors or outdoors. Give your guests the chance to have fun while enjoying good moments together, where each throw of the ball creates warm and unforgettable memories.",
              "Make your event resonate with our energetic and memorable DJ sets! Whether for a wedding, a corporate party, a birthday, or a gathering with friends, our professional DJs know how to create the perfect atmosphere to get your guests dancing. With a varied musical selection ranging from timeless classics to modern hits, each set is personalized to match the energy of your event. Expect captivating mixes that will rhythm your evening while ensuring an unforgettable experience. Offer your guests a festive and dynamically engaging atmosphere, where music and dance combine to create exceptional memories.",
              "Light up your events with our illuminated smart bracelets! These innovative accessories add a touch of sparkle and fun to all your celebrations, whether it’s weddings, birthdays, or corporate parties. With their multicolored lights and sleek design, our bracelets adapt to all styles and create an unforgettable festive atmosphere. Easy to use, they perfectly synchronize with the music, transforming every moment into a true light show. Give your guests a unique and memorable experience, where each bracelet sparkles like a star, illuminating smiles and laughter throughout the evening. Let the magic of lights work and make your event an exceptional moment!",
              "Awaken your senses and transform your events into true spectacles with our dancing flames! Perfect for adding a spectacular and warm atmosphere, these flames create a captivating environment that amazes your guests. Whether for a wedding, a corporate evening, or an outdoor festival, our flame installations bring a touch of magic and fascination to every moment. In addition to being visually stunning, they create a friendly atmosphere that encourages celebration. Let our flames illuminate your event and offer your guests an unforgettable experience, where warmth and brilliance combine for memorable memories."
            ]
          },
        },
        gallery: {
          title: "Photos Gallery",
          display_more: "Display more",
          display_less: "Display less",
        },
        they_trusted_us: {
          title: "They trusted us",
        },
        video: {
          title: "Presentation video",
        },
      },
      bicyclette: {
        title: "Bicyclette Smoothie Énergie",
        introduction: {
          title: "Pedal, we'll take care of the rest",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. <span>Etiam sit amet</span> ex eget dolor semper laoreet quis eu nunc. <span>Sed pulvinar</span> sapien lorem, sed iaculis lectus commodo a. Duis et condimentum urna, id facilisis nisl. Ut vehicula dolor metus. Interdum et malesuada <span>fames ac ante</span> ipsum primis in faucibus. Curabitur blandit nibh neque, eget lacinia arcu rhoncus dapibus. Pellentesque ut mi id velit <span>efficitur tempor</span>.",
        },
        follow_us: "Follow us on our networks!",
        gallery: {
          title: "Some images that will make you thirsty...",
        }
      },
      universes: {
        meta_description: "Discover Bracson's tailor-made services to make your events unique: foam cannons, confetti, bubbles, Smoothie Energy Bicycle, DJ sets, and more. Perfect for weddings, anniversaries, and corporate parties, our offerings adapt to all your needs.",
        title: "Our Universes",
        search: "Search with terms...",
        no_result_found: "No result found.",
        new: "New",
      },
      shop: {
        meta_description: "Explore our online store offering sweatshirts, t-shirts, caps, cups, fans, and tank tops. Trendy and stylish items for all occasions, with options for every season.",
        text: "The products presented <span>are not available for online purchase</span>. All <span>information</span>, including <span>prices</span>, is provided <span>for informational purposes</span> only. If you wish to <span>acquire a product</span> or have any questions about them, feel free to <span>contact us</span> via the <span>contact form</span> or on <span>our social media</span>",
        products: "Products",
        one_size: "One size",
      },
      contact: {
        meta_description: "Contact Bracson to turn your events into unforgettable moments. Whether for weddings, anniversaries, or corporate parties, our tailor-made services like foam cannons, confetti, bubbles, Smoothie Energy Bicycle, and DJ sets will make your celebration a success.",
        label: "Contact",
        title: "Contact us!",
        disclaimer: "All the fields are <span>required</span>.<br />Your email address must be <span>real</span>.",
        form: {
          name: {
            label: "Full name",
            placeholder: "John Doe",
          },
          email: {
            label: "Email address",
            placeholder: "john.doe@example.com",
          },
          subjects: {
            label: "Subject",
            general: "General inquiry",
            shop: "Shop",
            service: "Service",
          },
          message: {
            label: "Message",
            placeholder: "Start to type here...",
          },
          submit: "Send",
        },
        captcha_error: "Before proceeding, please check the CAPTCHA box.",
        error: "An error occurred while sending the message.<br /><br />Please try again later or contact me directly using the details provided below.",
        success: "Thank you for your message!<br />We will respond to you as soon as possible.<br /><br />If your email address is correct, you should have received an acknowledgment of this message. Please also check your spam folder if this is not the case. If you haven't received an acknowledgment of this message within 1 hour, feel free to contact us using the details below.",
        details: "Contact details",
        loading: "Thank you for your patience while the message is being sent, it should only take a few seconds...",
        cookies: {
          label: "Cookies",
          disclaimer: "This form is protected by <span>reCAPTCHA</span> to prevent spam. However, you have declined the <span>necessary cookies</span> for its functionality.<br />You can change your <span>cookie preferences</span> by clicking on the cookie icon at the <span>bottom right</span>.",
        },
        mail: {
          subject: "Acknowledgment of receipt of your message",
          body: "Thank you for taking the time to contact us. We have received your message and will respond to you as soon as possible.<br /><br />In the meantime, if you have any additional questions or if you wish to modify your request, please don't hesitate to reply directly to this email.<br /><br />Best regards,<br />The Bracson team",
        },
      },
      legal: {
        meta_description: "Review Bracson's legal notices to understand our commitments and responsibilities regarding data protection, intellectual property, and regulations. Transparency and compliance for a seamless experience.",
        site_information: {
          title: "Site Publisher",
          responsable: "Responsible",
          address: "Address",
          email_address: "Email address",
          phone_number: "Phone number",
          siret: "SIRET",
          vat_number: "VAT number",
        },
        hosting_provider: {
          title: "Hosting",
          text: "The site is hosted by:",
          text_2: "The site's photos and videos are hosted by:",
          hosting_provider: "Hosting Provider",
          stockage: "Storage Service",
          address: "Address",
          phone_number: "Phone number",
        },
        intellectual_property: {
          title: "Intellectual Property",
        },
        credits: {
          title: "Credits",
          design_development: "Design and Development",
          photos_and_videos: "Photos and Videos",
        },
        disclaimer: {
          title: "Reference Version",
          text: "In the event of any discrepancy between the translated versions of this document, the French version shall prevail.",
        },
      },
      terms: {
        meta_description: "Discover Bracson's Terms and Conditions of Use, which govern access to and use of our services. Learn about your rights and obligations when booking our offerings for unforgettable events.",
        introduction: {
          title: "Introduction",
          text: "These Terms of Service (TOS) are intended to define the terms of service provision for the Bracson website and the conditions of use of the website by the user.",
        },
        access: {
          title: "Access to the site",
          text: "The Bracson website is freely accessible to any user with Internet access. All costs related to access to the site, whether hardware, software, or Internet access, are the responsibility of the user.",
        },
        responsibility: {
          title: "Responsibility",
          text: "Bracson makes every effort to provide accurate and up-to-date information. However, we cannot guarantee the accuracy, completeness, or timeliness of the information on the site. Bracson cannot be held responsible for any damages related to the use of the site.",
        },
        intellectual_property: {
          title: "Intellectual Property",
          text: "All content available on the Bracson website, including, but not limited to, texts, images, videos, logos, and icons, are the property of Bracson or their respective authors. Any reproduction, distribution, or use of this content is prohibited without prior authorization.",
        },
        personal_data: {
          title: "Collection of Personal Data",
          text: "The information collected through the Bracson website is used exclusively to process your requests and improve our services. In accordance with GDPR, you have the right to access, rectify, and delete your personal data.",
        },
        cookies: {
          title: "Cookies",
        },
        external_links: {
          title: "External Links",
          text: "The Bracson website may contain links to other websites. Bracson is not responsible for the content of these external sites nor for the consequences of their use.",
        },
        modification: {
          title: "Modification of the TOS",
          text: "Bracson reserves the right to modify these TOS at any time. It is recommended to regularly consult this page to stay informed of changes.",
        },
        law: {
          title: "Applicable Law and Jurisdiction",
          text: "These TOS are governed by French law. In the event of a dispute, the French courts will have exclusive jurisdiction.",
        },
      },
      cookie: {
        meta_description: "Explore Bracson's cookie policy, which explains how we use cookies to enhance your online experience. Learn about the types of cookies we use and how to manage your preferences.",
        definition: {
          title: "What is a cookie?",
          text: "A cookie is a small text file placed on your device (computer, tablet, smartphone, etc.) by websites you visit. They are used to make websites work, improve your user experience, and provide certain information to site owners.",
        },
        type: {
          title: "Types of cookies used on our site",
          text: "We use different types of cookies on our site:",
          title_1: "Strictly necessary cookies",
          text_1: "These cookies are essential for the proper functioning of the site. Without these cookies, certain features (like access to secure areas) cannot be provided. Google reCAPTCHA falls under this type of cookie; if you choose to refuse non-essential cookies, you will not have access to the contact form to avoid potential spam.",
          title_2: "Performance and analytics cookies",
          text_2: "These cookies collect information about how visitors use our site, such as the most visited pages or any errors encountered. This data helps us improve the site's performance and the user experience. For example, we use Google Analytics to analyze site traffic.",
          title_3: "Functionality cookies",
          text_3: "These cookies allow us to remember your preferences (such as the selected language) to provide a personalized experience.",
        },
        gestion: {
          title: "Cookie management",
          text_2: "Most browsers allow you to control cookies through their settings. You can choose to block or delete certain cookies. Here are links to cookie management documentation for the most popular browsers:",
        },
        conservation: {
          title: "Cookie retention period",
          text: "Cookies have a limited lifespan that varies depending on their type. Session cookies are deleted when you close your browser, while persistent cookies remain on your device until their expiration (1 year) or manual deletion.",
        },
        droit: {
          title: "User rights",
          text: "In accordance with the General Data Protection Regulation (GDPR), you have the right to withdraw your consent regarding the use of non-essential cookies at any time. You can also exercise your rights of access, rectification, or deletion of data concerning you by contacting us at the following address: bracson.contact[a]gmail.com",
        },
        modification: {
          title: "Changes to the cookie policy",
          text: "We reserve the right to modify this cookie policy at any time to reflect changes in our practices or legislative developments. We encourage you to regularly check this page to stay informed of updates.",
        },
        actual_choice: " (Current choice)",
      },
      footer: {
        legal_notice: "Legal Notice",
        all_rights_reserved: "All Rights Reserved.",
        sitemap: "Site Map",
        useful_links: "Useful Links",
        terms: "Terms and Conditions",
        cookie: "Cookie Policy",
        powered_by: "Powered By",
      },
      not_found: {
        meta_description: "Oops! The page you are looking for on Bracson is not available. Explore our unique services for your events or return to the homepage to discover how we can make your occasions unforgettable.",
        label: "404 - Page not found",
        title: "404",
        text: "Ouch, seems you're lost...<br />But maybe we could help you to <span>find your path?</span>",
      },
    }
  }

  const universes = [
    {
      id: "bicyclette-smoothie-energie",
      index: 1,
      picture: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/2000/86586379-314e-4110-a5a6-19fb3fbf3519-min.webp",
      title: translations[locale].home.our_universes.items.titles[0],
      text: translations[locale].home.our_universes.items.texts[0],
      new: true,
      url: "/universes/bicyclette-smoothie-energie",
      page: "bicyclette",
      networks: [
        {
          title: "Instagram",
          icon: "fa-brands fa-instagram",
          url: "https://www.instagram.com/bicyclette_smoothie_energie",
        },
        {
          title: "LinkedIn",
          icon: "fa-brands fa-linkedin",
          url: "https://www.linkedin.com/in/bicyclette-smoothie-%C3%A9nergie/?trk=public_post_comment_actor-image&originalSubdomain=fr",
        },
      ],
      gallery: [
        {
          alt: "Un vélo smoothie",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/500/86586379-314e-4110-a5a6-19fb3fbf3519-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/2000/86586379-314e-4110-a5a6-19fb3fbf3519-min.webp",
          index: 1,
        },
        {
          alt: "Un vélo smoothie",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/500/4b287ff0-7c0d-4564-b63f-2f66fd0a8564-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/2000/4b287ff0-7c0d-4564-b63f-2f66fd0a8564-min.webp",
          index: 2,
        },
        {
          alt: "Une femme sur un vélo",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/500/1721724129690-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/2000/1721724129690-min.webp",
          index: 3,
        },
        {
          alt: "Une femme sur un vélo",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/500/74327681-4a49-40f0-b73c-1d62e65f5a01-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/2000/74327681-4a49-40f0-b73c-1d62e65f5a01-min.webp",
          index: 4,
        },
        {
          alt: "Une femme sur un vélo",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/500/7a05eb0d-6d3a-436c-a2ed-1903c92b4aa5-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/2000/7a05eb0d-6d3a-436c-a2ed-1903c92b4aa5-min.webp",
          index: 5,
        },
        {
          alt: "Deux hommes à un stand avec un vélo smoothie",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/500/38f885d3-ac95-4ecb-a624-c85e8ed95a74-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/2000/38f885d3-ac95-4ecb-a624-c85e8ed95a74-min.webp",
          index: 6,
        },
        {
          alt: "Un enfant sur un vélo smoothie",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/500/92391daf-9306-4f05-961f-ca9a9f0dfa1f-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/2000/92391daf-9306-4f05-961f-ca9a9f0dfa1f-min.webp",
          index: 7,
        },
        {
          alt: "Un vélo smoothie",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/500/c38e9e13-34e6-4d0e-9e37-aec52b36f5bb-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/2000/c38e9e13-34e6-4d0e-9e37-aec52b36f5bb-min.webp",
          index: 8,
        },
        {
          alt: "Un vélo smoothie",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/500/d12cb2c3-a59d-414f-9437-f0b9bc8cc57f-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/2000/d12cb2c3-a59d-414f-9437-f0b9bc8cc57f-min.webp",
          index: 9,
        },
        {
          alt: "Une femme sur un vélo",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/500/d245d735-b2c4-476b-978a-0def3b0ae0f1-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bicyclette%20Smoothie%20%C3%89nergie/2000/d245d735-b2c4-476b-978a-0def3b0ae0f1-min.webp",
          index: 10,
        },
      ],
    },
    {
      id: "machines-a-bulles",
      index: 2,
      picture: "https://bracson.b-cdn.net/Universes/Bubble%20Machines/2000/98fef766-4d3f-4189-9eb7-81b4d48c0898-min.webp",
      title: translations[locale].home.our_universes.items.titles[1],
      text: translations[locale].home.our_universes.items.texts[1],
      new: false,
      url: "/universes/machines-a-bulles",
      page: "",
      video: "https://www.youtube.com/embed/XGi6kcJl-BI?si=rjgm8BFkDA91EelV",
      networks: [],
      gallery: [
        {
          alt: "Une machine à bulles",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bubble%20Machines/500/98fef766-4d3f-4189-9eb7-81b4d48c0898-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bubble%20Machines/2000/98fef766-4d3f-4189-9eb7-81b4d48c0898-min.webp",
          index: 1,
        },
        {
          alt: "Des gens dans de la mousse et des bulles",
          thumbnail: "https://bracson.b-cdn.net/Universes/Foam%20Machines/500/9e8ce998-3d8a-4679-9939-985eb94be9be.jpg",
          src: "https://bracson.b-cdn.net/Universes/Foam%20Machines/2000/9e8ce998-3d8a-4679-9939-985eb94be9be.jpg",
          index: 2,
        },
        {
          alt: "Une fanfare avec des bulles autour",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bubble%20Machines/500/_73A8147-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bubble%20Machines/2000/_73A8147-min.webp",
          index: 3,
        },
        {
          alt: "Une femme à un stand avec des bulles autour",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bubble%20Machines/500/_73A6558-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bubble%20Machines/2000/_73A6558-min.webp",
          index: 4,
        },
        {
          alt: "Un homme joue avec des bulles",
          thumbnail: "https://bracson.b-cdn.net/Universes/Bubble%20Machines/500/_73A6555-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Bubble%20Machines/2000/_73A6555-min.webp",
          index: 5,
        },
      ],
    },
    {
      id: "machines-a-mousse",
      index: 3,
      picture: "https://bracson.b-cdn.net/Universes/Foam%20Machines/2000/f003d4af-3c07-4c8e-a6c4-848246e6a069.jpg",
      title: translations[locale].home.our_universes.items.titles[2],
      text: translations[locale].home.our_universes.items.texts[2],
      new: false,
      url: "/universes/machines-a-mousse",
      page: "",
      networks: [],
      gallery: [
        {
          alt: "Une machine à mousse",
          thumbnail: "https://bracson.b-cdn.net/Universes/Foam%20Machines/500/f003d4af-3c07-4c8e-a6c4-848246e6a069.jpg",
          src: "https://bracson.b-cdn.net/Universes/Foam%20Machines/2000/f003d4af-3c07-4c8e-a6c4-848246e6a069.jpg",
          index: 1,
        },
        {
          alt: "Un homme tient une machine à mousse",
          thumbnail: "https://bracson.b-cdn.net/Universes/Foam%20Machines/500/a69a0e83-0dbe-42d0-bdb7-9e6895577915.jpg",
          src: "https://bracson.b-cdn.net/Universes/Foam%20Machines/2000/a69a0e83-0dbe-42d0-bdb7-9e6895577915.jpg",
          index: 2,
        },
        {
          alt: "Des gens sont sous une machine à mousse",
          thumbnail: "https://bracson.b-cdn.net/Universes/Foam%20Machines/500/60643360-22f9-453c-afbc-86844a5ef75c.jpg",
          src: "https://bracson.b-cdn.net/Universes/Foam%20Machines/2000/60643360-22f9-453c-afbc-86844a5ef75c.jpg",
          index: 3,
        },
        {
          alt: "Des gens dans de la mousse et des bulles",
          thumbnail: "https://bracson.b-cdn.net/Universes/Foam%20Machines/500/9e8ce998-3d8a-4679-9939-985eb94be9be.jpg",
          src: "https://bracson.b-cdn.net/Universes/Foam%20Machines/2000/9e8ce998-3d8a-4679-9939-985eb94be9be.jpg",
          index: 4,
        },
        {
          alt: "Des gens dans la mousse",
          thumbnail: "https://bracson.b-cdn.net/Universes/Foam%20Machines/500/e12cb228-160b-41a6-bc94-187aef86071a.jpg",
          src: "https://bracson.b-cdn.net/Universes/Foam%20Machines/2000/e12cb228-160b-41a6-bc94-187aef86071a.jpg",
          index: 5,
        },
        {
          alt: "Une femme et des enfants dans la mousse",
          thumbnail: "https://bracson.b-cdn.net/Universes/Foam%20Machines/500/1626860798635-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Foam%20Machines/2000/1626860798635-min.webp",
          index: 6,
        },
        {
          alt: "Des enfants dans la mousse",
          thumbnail: "https://bracson.b-cdn.net/Universes/Foam%20Machines/500/1626860799077-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Foam%20Machines/2000/1626860799077-min.webp",
          index: 7,
        },
        {
          alt: "Des gens dans la mousse",
          thumbnail: "https://bracson.b-cdn.net/Universes/Foam%20Machines/500/1626860799297-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Foam%20Machines/2000/1626860799297-min.webp",
          index: 8,
        },
        {
          alt: "Un homme joue avec de la mousse",
          thumbnail: "https://bracson.b-cdn.net/Universes/Foam%20Machines/500/1626860798272-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Foam%20Machines/2000/1626860798272-min.webp",
          index: 9,
        },
        {
          alt: "Une femme et des enfants dans la mousse",
          thumbnail: "https://bracson.b-cdn.net/Universes/Foam%20Machines/500/_73A7539-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Foam%20Machines/2000/_73A7539-min.webp",
          index: 10,
        },
      ],
    },
    {
      id: "canons-a-confettis",
      index: 4,
      picture: "https://bracson.b-cdn.net/Universes/Confetti%20Cannons/2000/clem-onojeghuo-Yl2Bz4jbm44-unsplash.jpg",
      title: translations[locale].home.our_universes.items.titles[3],
      text: translations[locale].home.our_universes.items.texts[3],
      new: false,
      url: "/universes/canons-a-confettis",
      page: "",
      networks: [],
      gallery: [
        {
          alt: "Des flammes sortent de machines sur la scène devant une foule en délire",
          thumbnail: "https://bracson.b-cdn.net/Universes/Confetti%20Cannons/500/clem-onojeghuo-Yl2Bz4jbm44-unsplash.jpg",
          src: "https://bracson.b-cdn.net/Universes/Confetti%20Cannons/2000/clem-onojeghuo-Yl2Bz4jbm44-unsplash.jpg",
          index: 1,
        },
        {
          alt: "Des flammes sortent de machines sur la scène devant une foule en délire",
          thumbnail: "https://bracson.b-cdn.net/Universes/Confetti%20Cannons/500/aaron-paul-JMpnwlDTSBM-unsplash.jpg",
          src: "https://bracson.b-cdn.net/Universes/Confetti%20Cannons/2000/aaron-paul-JMpnwlDTSBM-unsplash.jpg",
          index: 2,
        },
        {
          alt: "Un homme chante sur scène avec des flammes derrière lui",
          thumbnail: "https://bracson.b-cdn.net/Universes/Confetti%20Cannons/500/keith-luke-GUAcpXPyFRc-unsplash.jpg",
          src: "https://bracson.b-cdn.net/Universes/Confetti%20Cannons/2000/keith-luke-GUAcpXPyFRc-unsplash.jpg",
          index: 3,
        },
        {
          alt: "Un DJ performe en haut d'une scène avec des flammes devant lui",
          thumbnail: "https://bracson.b-cdn.net/Universes/Confetti%20Cannons/500/may-gauthier-zE52B8J0Ed0-unsplash.jpg",
          src: "https://bracson.b-cdn.net/Universes/Confetti%20Cannons/2000/may-gauthier-zE52B8J0Ed0-unsplash.jpg",
          index: 4,
        },
        {
          alt: "Des flammes sortent d'une scène",
          thumbnail: "https://bracson.b-cdn.net/Universes/Confetti%20Cannons/500/seth-reese-lA2qXhn4L2A-unsplash.jpg",
          src: "https://bracson.b-cdn.net/Universes/Confetti%20Cannons/2000/seth-reese-lA2qXhn4L2A-unsplash.jpg",
          index: 5,
        },
      ],
    },
    {
      id: "terrain-de-petanque-ephemere",
      index: 5,
      picture: "https://bracson.b-cdn.net/Universes/Temporary%20Petanque%20Court/2000/_73A6607-min.webp",
      title: translations[locale].home.our_universes.items.titles[4],
      text: translations[locale].home.our_universes.items.texts[4],
      new: false,
      url: "/universes/terrain-de-petanque-ephemere",
      page: "",
      networks: [],
      gallery: [
        {
          alt: "Un enfant sur un terrain de pétanque",
          thumbnail: "https://bracson.b-cdn.net/Universes/Temporary%20Petanque%20Court/500/_73A6607-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Temporary%20Petanque%20Court/2000/_73A6607-min.webp",
          index: 1,
        },
        {
          alt: "Un terrain de pétanque",
          thumbnail: "https://bracson.b-cdn.net/Universes/Temporary%20Petanque%20Court/500/892c9588-729f-44ff-bcfc-9dbba931a9ab-min.webp",
          src: "https://bracson.b-cdn.net/Universes/Temporary%20Petanque%20Court/2000/892c9588-729f-44ff-bcfc-9dbba931a9ab-min.webp",
          index: 2,
        },
      ],
    },
    {
      id: "dj",
      index: 6,
      picture: "https://bracson.b-cdn.net/Universes/DJ/2000/_A8A7781-min.webp",
      title: translations[locale].home.our_universes.items.titles[5],
      text: translations[locale].home.our_universes.items.texts[5],
      new: false,
      url: "/universes/dj",
      page: "",
      networks: [],
      gallery: [
        {
          alt: "Une foule danse dans la fumée",
          thumbnail: "https://bracson.b-cdn.net/Universes/DJ/500/_A8A7781-min.webp",
          src: "https://bracson.b-cdn.net/Universes/DJ/2000/_A8A7781-min.webp",
          index: 1,
        },
        {
          alt: "Une femme avec un bracelet fluo dans la pénombre",
          thumbnail: "https://bracson.b-cdn.net/Universes/DJ/500/_73A9051-min.webp",
          src: "https://bracson.b-cdn.net/Universes/DJ/2000/_73A9051-min.webp",
          index: 2,
        },
        {
          alt: "Des hommes dansent devant une barrière",
          thumbnail: "https://bracson.b-cdn.net/Universes/DJ/500/_73A9113-min.webp",
          src: "https://bracson.b-cdn.net/Universes/DJ/2000/_73A9113-min.webp",
          index: 3,
        },
        {
          alt: "Une foule danse sous les néons",
          thumbnail: "https://bracson.b-cdn.net/Universes/DJ/500/1654591810196-min.webp",
          src: "https://bracson.b-cdn.net/Universes/DJ/2000/1654591810196-min.webp",
          index: 4,
        },
        {
          alt: "Une foule devant des lasers",
          thumbnail: "https://bracson.b-cdn.net/Universes/DJ/500/1654591772959-min.webp",
          src: "https://bracson.b-cdn.net/Universes/DJ/2000/1654591772959-min.webp",
          index: 5,
        },
        {
          alt: "Une foule devant des lasers",
          thumbnail: "https://bracson.b-cdn.net/Universes/DJ/500/_A8A7883-min.webp",
          src: "https://bracson.b-cdn.net/Universes/DJ/2000/_A8A7883-min.webp",
          index: 6,
        },
        {
          alt: "Une foule devant des lasers",
          thumbnail: "https://bracson.b-cdn.net/Universes/DJ/500/_A8A7830-min.webp",
          src: "https://bracson.b-cdn.net/Universes/DJ/2000/_A8A7830-min.webp",
          index: 7,
        },
        {
          alt: "Un DJ performe en haut d'une scène",
          thumbnail: "https://bracson.b-cdn.net/Universes/DJ/500/_A8A7699-min.webp",
          src: "https://bracson.b-cdn.net/Universes/DJ/2000/_A8A7699-min.webp",
          index: 8,
        },
      ],
    },
    {
      id: "bracelets-connectes-lumineux",
      index: 7,
      picture: "https://bracson.b-cdn.net/Universes/Illuminated%20Smart%20Bracelets/2000/Light-Bracelets_8e21f77c-33d1-46ce-824a-3652ccf7d3d0.webp",
      title: translations[locale].home.our_universes.items.titles[6],
      text: translations[locale].home.our_universes.items.texts[6],
      new: false,
      url: "/universes/bracelets-connectes-lumineux",
      page: "",
      networks: [],
      gallery: [
        {
          alt: "Des flammes sortent de machines sur la scène devant une foule en délire",
          thumbnail: "https://bracson.b-cdn.net/Universes/Illuminated%20Smart%20Bracelets/500/Light-Bracelets_8e21f77c-33d1-46ce-824a-3652ccf7d3d0.webp",
          src: "https://bracson.b-cdn.net/Universes/Illuminated%20Smart%20Bracelets/2000/Light-Bracelets_8e21f77c-33d1-46ce-824a-3652ccf7d3d0.webp",
          index: 1,
        },
      ],
    },
    {
      id: "flammes",
      index: 8,
      picture: "https://bracson.b-cdn.net/Universes/Flames/2000/andre-benz-Jb7TLs6fW_I-unsplash.jpg",
      title: translations[locale].home.our_universes.items.titles[7],
      text: translations[locale].home.our_universes.items.texts[7],
      new: true,
      url: "/universes/flammes",
      page: "",
      networks: [],
      gallery: [
        {
          alt: "Des flammes sortent de machines sur la scène devant une foule en délire",
          thumbnail: "https://bracson.b-cdn.net/Universes/Flames/500/andre-benz-Jb7TLs6fW_I-unsplash.jpg",
          src: "https://bracson.b-cdn.net/Universes/Flames/2000/andre-benz-Jb7TLs6fW_I-unsplash.jpg",
          index: 1,
        },
        {
          alt: "Des flammes sortent de machines sur la scène devant une foule en délire",
          thumbnail: "https://bracson.b-cdn.net/Universes/Flames/500/chris-leipelt-4i7xBIboSOE-unsplash.jpg",
          src: "https://bracson.b-cdn.net/Universes/Flames/2000/chris-leipelt-4i7xBIboSOE-unsplash.jpg",
          index: 2,
        },
        {
          alt: "Un homme chante sur scène avec des flammes derrière lui",
          thumbnail: "https://bracson.b-cdn.net/Universes/Flames/500/james-jeremy-beckers-6Pa9QH9I71A-unsplash.jpg",
          src: "https://bracson.b-cdn.net/Universes/Flames/2000/james-jeremy-beckers-6Pa9QH9I71A-unsplash.jpg",
          index: 3,
        },
        {
          alt: "Un DJ performe en haut d'une scène avec des flammes devant lui",
          thumbnail: "https://bracson.b-cdn.net/Universes/Flames/500/jorik-kleen-Nio0W6TWkaU-unsplash.jpg",
          src: "https://bracson.b-cdn.net/Universes/Flames/2000/jorik-kleen-Nio0W6TWkaU-unsplash.jpg",
          index: 4,
        },
        {
          alt: "Des flammes sortent d'une scène",
          thumbnail: "https://bracson.b-cdn.net/Universes/Flames/500/vicky-hladynets-v1mq6dSH7eU-unsplash.jpg",
          src: "https://bracson.b-cdn.net/Universes/Flames/2000/vicky-hladynets-v1mq6dSH7eU-unsplash.jpg",
          index: 5,
        },
      ],
    },
  ];

  const networks = [
    {
      title: "Instagram",
      icon: "fa-brands fa-instagram",
      url: "https://www.instagram.com/bracson_/",
    },
    {
      title: "Facebook",
      icon: "fa-brands fa-facebook",
      url: "https://m.facebook.com/p/Soci%C3%A9t%C3%A9-Bracson-100094464843099/",
    },
  ];

  const [initializedPage, setInitializedPage] = useState("");

  useEffect(() => {
    if (page !== "home") {
      setFinished(true);
    }
  });

  useEffect(() => {
    predictBreakpoint();
    return () => { }
  });

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      predictBreakpoint();
    }, 1);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    }
  });

  const debounce = (fn, ms) => {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this, arguments);
      }, ms)
    };
  }

  const predictBreakpoint = () => {
    if (window.innerWidth < 576) {
      setCurrentBreakpoint('xs');
    } else if (window.innerWidth >= 576 && window.innerWidth < 768) {
      setCurrentBreakpoint('s');
    } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
      setCurrentBreakpoint('m');
    } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
      setCurrentBreakpoint('l');
    } else if (window.innerWidth >= 1200 && window.innerWidth < 1400) {
      setCurrentBreakpoint('xl');
    } else if (window.innerWidth >= 1400) {
      setCurrentBreakpoint('xxl');
    }
  }

  return (
    <div className="app">
      <Router>
        <Locale.Provider value={{ locale: locale, setLocale: setLocale, translations: translations }}>
          <Title.Provider value={{ page: page, setPage: setPage, ancestors: ancestors, setAncestors: setAncestors }}>
            <Window.Provider value={{ scrollPosition: scrollPosition, setScrollPosition: setScrollPosition, currentBreakpoint: currentBreakpoint, setCurrentBreakpoint: setCurrentBreakpoint }}>
              <Content.Provider value={{ universes: universes, networks: networks, finished: finished, setFinished: setFinished, initializedPage: initializedPage, setInitializedPage: setInitializedPage }}>
                <Header />
                {page !== "home" &&
                  <Breadcrumb ancestors={ancestors} />
                }
                <div className={`app__container ${page}`}>
                  <Routes>
                    <Route path="*" element={<PageNotFound />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/universes" element={<Universes />} />
                    <Route path="/universes/:id" element={<Service />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/legal" element={<Legal />} />
                    <Route path="/terms-and-conditions" element={<Terms />} />
                    <Route path="/cookies" element={<Cookies />} />
                  </Routes>
                  <Footer />
                </div>
                <CookieBanner />
              </Content.Provider>
            </Window.Provider>
          </Title.Provider>
        </Locale.Provider>
      </Router>
    </div>
  );
}

export default App;
library.add(fab, fas, far);
