import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { onSnapshot, doc, setDoc, collection, where, query, limit } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from '../utils/firebase';
import { Window, Locale, Title, Content } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Contact() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, translations } = useContext(Locale);
    const { setPage, setAncestors } = useContext(Title);

    const [cookies] = useCookies(['analyticsCookies']);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [captchaChecked, setCaptchaChecked] = useState(true);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [id, setId] = useState(null);

    const radius = 40;
    const strokeWidth = 7;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (percentage / 100) * circumference;

    const subjects = [
        {
            value: "Demande générale",
            label: translations[locale].contact.form.subjects.general,
        },
        {
            value: "Boutique",
            label: translations[locale].contact.form.subjects.shop,
        },
        {
            value: "Prestation",
            label: translations[locale].contact.form.subjects.service,
        },
    ];

    const [subject, setSubject] = useState(subjects[0].value);
    const [onPage, setOnPage] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setOnPage(true);
        setPage("contact");
        setAncestors([
            { name: translations[locale].contact.label, url: "/contact" },
        ]);
    }, []);

    const handleEmail = async (e) => {
        e.preventDefault();

        if (!captchaValue) {
            setCaptchaChecked(false);
            return;
        }

        setLoading(true);

        try {
            getProgressivePercentage(0, 20);

            const response = await axios.post('https://us-central1-bracson.cloudfunctions.net/verifyRecaptcha', {
                token: captchaValue,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                try {
                    getProgressivePercentage(20, 40);

                    let words = name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                    let formatedName = words.join(' ');

                    const newMail = {
                        id: uuidv4(),
                        from: `bracson.contact@gmail.com`,
                        replyTo: `${formatedName} <${email}>`,
                        to: "bracson.contact@gmail.com",
                        message: {
                            subject: `[${subject}] de "${formatedName}"`,
                            html: `${message.replace(/\r?\n/g, "<br />")}<br /><br /><i>Informations de l'expéditeur</i> :<br />• <strong>Nom complet</strong> : ${formatedName}<br />• <strong>Adresse email</strong> : ${email}`,
                        },
                    };

                    const newReceipt = {
                        id: uuidv4(),
                        from: "Bracson <bracson.contact@gmail.com>",
                        replyTo: "Bracson <bracson.contact@gmail.com>",
                        to: email,
                        message: {
                            subject: translations[locale].contact.mail.subject,
                            html: `${locale === "fr" ? "Bonjour " : "Hello "}${formatedName},<br /><br />${translations[locale].contact.mail.body.replace(/\r?\n/g, "<br />")}`,
                        },
                    };

                    setId(newMail.id);

                    try {
                        const mailRef = doc(collection(db, "mail"), newMail.id);
                        const receiptRef = doc(collection(db, "mail"), newReceipt.id);
                        await setDoc(mailRef, newMail);
                        await setDoc(receiptRef, newReceipt);
                    } catch (error) {
                        setError(true);
                        setLoading(false);
                    }
                } catch (error) {
                    console.error('Error sending email:', error);
                    setError(true);
                    setLoading(false);
                }
            } else {
                console.error('reCAPTCHA validation failed: ', response.data.error);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error during form submission: ', error);
            setLoading(false);
        }
    }

    const onCaptchaChange = (value) => {
        setCaptchaValue(value);
        setCaptchaChecked(true);
    };

    useEffect(() => {
        const mailsUnsub = onSnapshot(query(collection(db, "mail"), where("id", "==", id), limit(1)), (querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                const mail = {
                    id: doc.id,
                    delivery: doc.data().delivery,
                };

                if (mail.delivery !== undefined && mail.delivery !== null && mail.delivery.state !== null && mail.delivery.state !== undefined) {
                    switch (mail.delivery.state) {
                        case 'PENDING':
                            getProgressivePercentage(40, 60);
                            break;
                        case 'PROCESSING':
                            getProgressivePercentage(60, 80);
                            break;
                        case 'ERROR':
                            getProgressivePercentage(80, 100);
                            setError(true);
                            setLoading(false);
                            break;
                        case 'SUCCESS':
                            getProgressivePercentage(80, 100);
                            setSuccess(true);
                            setLoading(false);
                            break;
                        default:
                            setError(true);
                            setLoading(false);
                            break;
                    }
                }
            });
        });

        return () => {
            mailsUnsub();
        };
    }, [id]);

    const getProgressivePercentage = (start, end) => {
        let startingValue = start;

        const interval = setInterval(() => {
            if (startingValue === end) {
                clearInterval(interval);
            }

            setPercentage(startingValue);
            startingValue = startingValue + 1;
        }, 20);
    }

    return (
        <div className={`home init ${onPage ? "init--achieved" : ""}`}>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Bracson | {translations[locale].contact.label}
                    </title>
                    <meta name="description"
                        content={translations[locale].contact.meta_description} />
                    <meta name="og:description"
                        content={translations[locale].contact.meta_description} />
                </Helmet>
            </HelmetProvider>
            <div className="container">
                <div className={`home__content container__content home__content--page ${currentBreakpoint}`}>
                    <div className="home__content__block home__content__block--page">
                        <div className="contact">
                            <h1 className="contact__title"
                                dangerouslySetInnerHTML={{ __html: translations[locale].contact.title }}>
                            </h1>
                            {loading ?
                                <div className="contact__loading">
                                    <svg className="contact__progress"
                                        width="100"
                                        height="100"
                                        viewBox="0 0 100 100">
                                        <circle className="contact__progress__background"
                                            cx="50"
                                            cy="50"
                                            r={radius}
                                            strokeWidth={strokeWidth} />
                                        <circle className="contact__progress__circle"
                                            cx="50"
                                            cy="50"
                                            r={radius}
                                            strokeWidth={strokeWidth}
                                            style={{ strokeDasharray: circumference, strokeDashoffset: offset }} />
                                        <text className="contact__progress__text"
                                            x="50%"
                                            y="-47px"
                                            dominantBaseline="middle"
                                            textAnchor="middle">
                                            {percentage}%
                                        </text>
                                    </svg>
                                    {translations[locale].contact.loading}
                                </div>
                                : <React.Fragment>
                                    {!error && !success ?
                                        <React.Fragment>
                                            <p className="contact__disclaimer"
                                                dangerouslySetInnerHTML={{ __html: translations[locale].contact.disclaimer }}>
                                            </p>
                                            <form className={`contact__form ${currentBreakpoint}`}
                                                onSubmit={handleEmail}>
                                                <div className={`contact__form__row ${currentBreakpoint}`}>
                                                    <div className="contact__form__field">
                                                        <label htmlFor="your-full-name"
                                                            className="contact__form__field__label">
                                                            {translations[locale].contact.form.name.label}
                                                        </label>
                                                        <input id="your-full-name"
                                                            className={`contact__form__field__input full-name ${currentBreakpoint}`}
                                                            required
                                                            type="text"
                                                            placeholder={translations[locale].contact.form.name.placeholder}
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)} />
                                                    </div>
                                                    <div className="contact__form__field">
                                                        <label htmlFor="your-email"
                                                            className="contact__form__field__label">
                                                            {translations[locale].contact.form.email.label}
                                                        </label>
                                                        <input id="your-email"
                                                            className={`contact__form__field__input ${currentBreakpoint}`}
                                                            required
                                                            type="email"
                                                            placeholder={translations[locale].contact.form.email.placeholder}
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="contact__form__field">
                                                    <label htmlFor="your-subject"
                                                        className="contact__form__field__label">
                                                        {translations[locale].contact.form.subjects.label}
                                                    </label>
                                                    <select id="your-subject"
                                                        className={`contact__form__field__select ${currentBreakpoint}`}
                                                        required
                                                        value={subject}
                                                        onChange={(e) => setSubject(e.target.value)}>
                                                        {subjects.map((subject, index) => (
                                                            <option key={index}
                                                                className="contact__form__field__select__option"
                                                                value={subject.value}>
                                                                {subject.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                                                </div>
                                                <div className="contact__form__field">
                                                    <label htmlFor="your-message"
                                                        className="contact__form__field__label">
                                                        {translations[locale].contact.form.message.label}
                                                    </label>
                                                    <textarea id="your-message"
                                                        className={`contact__form__field__textarea ${currentBreakpoint}`}
                                                        required
                                                        placeholder={translations[locale].contact.form.message.placeholder}
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}>
                                                    </textarea>
                                                </div>
                                                {cookies.recaptchaCookies &&
                                                    <div className="contact__form__captcha">
                                                        <ReCAPTCHA
                                                            sitekey={process.env.REACT_APP_reCaptchaSiteKey}
                                                            onChange={onCaptchaChange} />
                                                    </div>
                                                }
                                                {!captchaChecked &&
                                                    <p className="contact__error">
                                                        {translations[locale].contact.captcha_error}
                                                    </p>
                                                }
                                                <button className="button button--more contact__form__submit"
                                                    onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                                                    {translations[locale].contact.form.submit}
                                                    <FontAwesomeIcon icon="fa-solid fa-paper-plane" />
                                                </button>
                                            </form>
                                        </React.Fragment>
                                        : <React.Fragment>
                                            <div className="contact__result">
                                                {error ?
                                                    <p className="contact__result__error"
                                                        dangerouslySetInnerHTML={{ __html: translations[locale].contact.error }}>
                                                    </p>
                                                    : <p className="contact__result__success"
                                                        dangerouslySetInnerHTML={{ __html: translations[locale].contact.success }}>
                                                    </p>
                                                }
                                            </div>
                                            <div className="home__content__block contact__details">
                                                <h1 className={`home__content__block__title ${currentBreakpoint}`}>
                                                    {translations[locale].contact.details}
                                                </h1>
                                            </div>
                                            <div className="contact__details__info">
                                                <a className="contact__details__info__item"
                                                    href="mailto:bracson.contact@gmail.com">
                                                    <div className="contact__details__info__item__icon">
                                                        <FontAwesomeIcon icon="fa-solid fa-envelope" />
                                                    </div>
                                                    bracson.contact@gmail.com
                                                </a>
                                                <a className="contact__details__info__item"
                                                    href="tel:0601020304">
                                                    <div className="contact__details__info__item__icon">
                                                        <FontAwesomeIcon icon="fa-solid fa-phone" />
                                                    </div>
                                                    +33 6 30 92 41 21
                                                </a>
                                                <a className="contact__details__info__item"
                                                    href="https://www.instagram.com/bracson_/"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <span className="contact__details__info__item__icon">
                                                        <FontAwesomeIcon icon="fa-brands fa-instagram" />
                                                    </span>
                                                    @bracson_
                                                </a>
                                                <a className="contact__details__info__item"
                                                    href="https://m.facebook.com/p/Soci%C3%A9t%C3%A9-Bracson-100094464843099/"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <div className="contact__details__info__item__icon">
                                                        <FontAwesomeIcon icon="fa-brands fa-facebook" />
                                                    </div>
                                                    Bracson
                                                </a>
                                            </div>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            } {!cookies.recaptchaCookies &&
                                <div className="contact__cookies">
                                    <p className="contact__cookies__disclaimer"
                                        dangerouslySetInnerHTML={{ __html: translations[locale].contact.cookies.disclaimer }}>
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}