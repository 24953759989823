import React, { useContext, useEffect, useState, Component } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Window, Locale, Title, Content } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Intro from './Intro';
import Lightbox from './Lightbox';
import Wave from '../assets/wave.png';

export default function Home() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, translations } = useContext(Locale);
    const { setPage } = useContext(Title);
    const { universes, networks } = useContext(Content);

    const navigateTo = useNavigate("");

    const [settings, setSettings] = useState({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
    });

    const [muted, setMuted] = useState(true);
    const [index, setIndex] = useState(1);
    const [displayLightbox, setDisplayLightbox] = useState(false);
    const [displayedPhotos, setDisplayedPhotos] = useState(4);
    const [incrementValue, setIncrementValue] = useState(4);

    const gallery = [
        {
            alt: "Un homme accroche des ballons",
            thumbnail: "https://bracson.b-cdn.net/Home/500/_73A6574-min.webp",
            src: "https://bracson.b-cdn.net/Home/2000/_73A6574-min.webp",
            index: 1,
        },
        {
            alt: "Une femme sourit en gonflant des ballons",
            thumbnail: "https://bracson.b-cdn.net/Home/500/_73A6579-min.webp",
            src: "https://bracson.b-cdn.net/Home/2000/_73A6579-min.webp",
            index: 2,
        },
        {
            alt: "Un homme est au téléphone et une femme est à une table à un stand",
            thumbnail: "https://bracson.b-cdn.net/Home/500/_73A6549-min.webp",
            src: "https://bracson.b-cdn.net/Home/2000/_73A6549-min.webp",
            index: 3,
        },
        {
            alt: "Plusieurs personnes discutent",
            thumbnail: "https://bracson.b-cdn.net/Home/500/_73A6835-min.webp",
            src: "https://bracson.b-cdn.net/Home/2000/_73A6835-min.webp",
            index: 4,
        },
        {
            alt: "Un stand avec des personnes assises",
            thumbnail: "https://bracson.b-cdn.net/Home/500/_73A7919-min.webp",
            src: "https://bracson.b-cdn.net/Home/2000/_73A7919-min.webp",
            index: 5,
        },
        {
            alt: "Un stand avec des personnes assises",
            thumbnail: "https://bracson.b-cdn.net/Home/500/_73A7923-min.webp",
            src: "https://bracson.b-cdn.net/Home/2000/_73A7923-min.webp",
            index: 6,
        },
        {
            alt: "Une foule se tient dehors",
            thumbnail: "https://bracson.b-cdn.net/Home/500/_73A8408-min.webp",
            src: "https://bracson.b-cdn.net/Home/2000/_73A8408-min.webp",
            index: 7,
        },
        {
            alt: "Plusieurs personnes à un stand autour d'une table",
            thumbnail: "https://bracson.b-cdn.net/Home/500/_A8A5898-min.webp",
            src: "https://bracson.b-cdn.net/Home/2000/_A8A5898-min.webp",
            index: 8,
        },
        {
            alt: "Un homme accroche des ballons à un stand",
            thumbnail: "https://bracson.b-cdn.net/Home/500/_A8A5931-min.webp",
            src: "https://bracson.b-cdn.net/Home/2000/_A8A5931-min.webp",
            index: 9,
        },
        {
            alt: "Deux hommes accrochent des ballons à un stand",
            thumbnail: "https://bracson.b-cdn.net/Home/500/_A8A5936-min.webp",
            src: "https://bracson.b-cdn.net/Home/2000/_A8A5936-min.webp",
            index: 10,
        },
    ];

    const logos = [
        {
            src: "https://bracson.b-cdn.net/Logo/cgr.png",
            title: "CGR Cinémas",
        },
        {
            src: "https://bracson.b-cdn.net/Logo/cmcas.png",
            title: "C.M.C.A.S.",
        },
        {
            src: "https://bracson.b-cdn.net/Logo/cmcas2.png",
            title: "C.M.C.A.S.",
        },
        {
            src: "https://bracson.b-cdn.net/Logo/creaflock.png",
            title: "Creaflock - Nancy",
        },
        {
            src: "https://bracson.b-cdn.net/Logo/elliv_agency.png",
            title: "Elliv Agency",
        },
        {
            src: "https://bracson.b-cdn.net/Logo/ketu_bara.png",
            title: "Ketu Bara",
        },
        {
            src: "https://bracson.b-cdn.net/Logo/les_jobastres.png",
            title: "Les Jobastres",
        },
        {
            src: "https://bracson.b-cdn.net/Logo/lo_oai.png",
            title: "Logo",
        },
        {
            src: "https://bracson.b-cdn.net/Logo/pauc.png",
            title: "PAUC",
        },
        {
            src: "https://bracson.b-cdn.net/Logo/shlag_market.png",
            title: "Shlag Market",
        },
        {
            src: "https://bracson.b-cdn.net/Logo/spartiates.png",
            title: "Marseille Spartiates Hockey Club",
        },
    ];

    const [onPage, setOnPage] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setOnPage(true);
        setPage("home");
    }, []);

    useEffect(() => {
        if (["xl", "xxl"].includes(currentBreakpoint)) {
            setIncrementValue(5);
            setDisplayedPhotos(5);
            setSettings((prevSettings) => ({
                ...prevSettings,
                slidesToShow: 4,
            }));
        } else if (["l"].includes(currentBreakpoint)) {
            setIncrementValue(5);
            setDisplayedPhotos(5);
            setSettings((prevSettings) => ({
                ...prevSettings,
                slidesToShow: 3,
            }));
        } else if (["m"].includes(currentBreakpoint)) {
            setIncrementValue(4);
            setDisplayedPhotos(4);
            setSettings((prevSettings) => ({
                ...prevSettings,
                slidesToShow: 2,
            }));
        } else {
            setIncrementValue(2);
            setDisplayedPhotos(4);
            setSettings((prevSettings) => ({
                ...prevSettings,
                slidesToShow: 1,
            }));
        }
    }, [currentBreakpoint]);

    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Bracson | {translations[locale].header.home}
                    </title>
                    <meta name="description"
                        content={translations[locale].home.life_is_a_party.meta_description} />
                    <meta name="og:description"
                        content={translations[locale].home.life_is_a_party.meta_description} />
                </Helmet>
            </HelmetProvider>
            <Intro />
            <div className={`home init ${onPage ? "init--achieved" : ""}`}>
                <div className="home__picture">
                    <video className="home__picture__image"
                        src="https://bracson.b-cdn.net/hero_video.mp4"
                        muted={muted}
                        autoPlay={true}
                        preload="auto"
                        loop={true}
                        disablePictureInPicture
                        playsInline>
                    </video>
                    <div className={`home__picture__wave ${currentBreakpoint}`}>
                        <img className="home__picture__wave__image"
                            src={Wave}
                            alt="Wave" />
                    </div>
                    <button className={`home__picture__button ${currentBreakpoint}`}
                        onClick={() => setMuted(muted ? false : true)}>
                        {muted ?
                            <FontAwesomeIcon icon="fa-solid fa-volume-xmark" />
                            : <FontAwesomeIcon icon="fa-solid fa-volume-high" />
                        }
                    </button>
                </div>
                <div className="container">
                    <div className={`home__content home__content--wave container__content ${currentBreakpoint}`}>
                        <div className="home__content__block">
                            <h1 className={`home__content__block__title ${currentBreakpoint}`}>
                                {translations[locale].home.life_is_a_party.title}
                            </h1>
                            <p className="home__content__block__text"
                                dangerouslySetInnerHTML={{ __html: translations[locale].home.life_is_a_party.text }}>
                            </p>
                            <div className="home__content__block__links">
                                <Link className="button button--arrow"
                                    to="/contact">
                                    <div className="button--arrow__icon">
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                    </div>
                                    {translations[locale].header.contact}
                                </Link>
                                {networks.map((network, index) => (
                                    <a key={index}
                                        className="button button--more"
                                        href={network.url}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={network.icon} />
                                        {network.title}
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="home__content__block--carousel">
                            <div className={`home__content__block__header ${currentBreakpoint}`}>
                                <h1 className={`home__content__block__title ${currentBreakpoint}`}>
                                    {translations[locale].home.our_universes.title}
                                </h1>
                                <Link className="button button--more"
                                    to="/universes">
                                    {translations[locale].home.our_universes.explore_all}
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                </Link>
                            </div>
                            <div className={`home__content__block__slider ${currentBreakpoint}`}>
                                <Slider {...settings}>
                                    {universes.map((universe, index) => (
                                        <div key={index}
                                            className={`home__content__universes__list__item universes__list__item ${currentBreakpoint}`}
                                            onClick={() => navigateTo(universe.url)}>
                                            <img className={`home__content__universes__list__item__picture image-loading ${currentBreakpoint}`}
                                                src={universe.picture}
                                                alt={universe.title}
                                                loading="lazy" />
                                            <div className="home__content__universes__list__item__info">
                                                <h2 className="home__content__universes__list__item__info__title">
                                                    {universe.title}
                                                </h2>
                                                {universe.new &&
                                                    <div className="ribbon">
                                                        <div className="ribbon__text">
                                                            {translations[locale].universes.new}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                        <div className="home__content__block">
                            <h1 className={`home__content__block__title ${currentBreakpoint}`}>
                                {translations[locale].home.gallery.title}
                            </h1>
                            <div className="home__content__block__gallery">
                                {gallery.slice(0, displayedPhotos).map(photo => (
                                    <div key={photo.index}
                                        className={`home__content__block__gallery__image ${currentBreakpoint}`}>
                                        <img className="home__content__block__gallery__image__img image-loading"
                                            src={photo.thumbnail}
                                            alt={photo.alt}
                                            loading="lazy"
                                            onClick={() => {
                                                setIndex(photo.index);
                                                setDisplayLightbox(true);
                                            }} />
                                    </div>
                                ))}
                            </div>
                            <div className="home__content__block__display">
                                {displayedPhotos > incrementValue && displayedPhotos > 4 &&
                                    <button className="home__content__block__display__button button button--more"
                                        onClick={() => {
                                            setDisplayedPhotos(prevDisplayedPhotos => prevDisplayedPhotos - incrementValue < incrementValue ? incrementValue : prevDisplayedPhotos - incrementValue);
                                        }}>
                                        {translations[locale].home.gallery.display_less} -
                                    </button>
                                }
                                {displayedPhotos < gallery.length &&
                                    <button className="home__content__block__display__button button button--more"
                                        onClick={() => {
                                            setDisplayedPhotos(prevDisplayedPhotos => prevDisplayedPhotos + incrementValue >= gallery.length ? gallery.length : prevDisplayedPhotos + incrementValue);
                                        }}>
                                        {translations[locale].home.gallery.display_more} +
                                    </button>
                                }
                            </div>
                        </div>
                        <div className="home__content__block home__content__block--logos">
                            <h1 className={`home__content__block__title ${currentBreakpoint}`}>
                                {translations[locale].home.they_trusted_us.title}
                            </h1>
                            <div className={`home__content__logos ${currentBreakpoint}`}>
                                <div className="home__content__logos__items">
                                    {[1, 2, 3, 4].map((iteration) => (
                                        <React.Fragment key={iteration}>
                                            {logos.map((logo, index) => (
                                                <div key={index}
                                                    className={`home__content__logos__items__item ${currentBreakpoint}`}>
                                                    <img className="home__content__logos__items__item__image"
                                                        src={logo.src}
                                                        alt={logo.title}
                                                        loading="lazy" />
                                                </div>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {displayLightbox &&
                <Lightbox
                    gallery={gallery}
                    activeIndex={index}
                    setIndex={setIndex}
                    setDisplay={setDisplayLightbox} />
            }
        </React.Fragment>
    )
}