import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Window, Locale } from '../App';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CookieBanner() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, setLocale, translations } = useContext(Locale);

    const [cookies, setCookie] = useCookies(['user']);
    const [showBanner, setShowBanner] = useState(!cookies.userConsent);
    const [done, setDone] = useState(cookies.userConsent);
    const [personalize, setPersonalize] = useState(false);
    const [recaptcha, setRecaptcha] = useState(cookies.recaptchaCookies ?? false);
    const [analytics, setAnalytics] = useState(cookies.analyticsCookies ?? false);

    const checkAlreadyDone = () => {
        if (done) {
            window.location.reload();
        } else {
            setCookie('userConsent', true, { path: '/', maxAge: 3600 * 24 * 365 });
            setDone(true);
        }
    }

    const confirmCookies = () => {
        setCookie('recaptchaCookies', recaptcha, { path: '/', maxAge: 3600 * 24 * 365 });
        setCookie('analyticsCookies', analytics, { path: '/', maxAge: 3600 * 24 * 365 });
        setShowBanner(false);
        setPersonalize(false);
        checkAlreadyDone();
    };

    const acceptCookies = () => {
        setCookie('recaptchaCookies', true, { path: '/', maxAge: 3600 * 24 * 365 });
        setCookie('analyticsCookies', true, { path: '/', maxAge: 3600 * 24 * 365 });
        setShowBanner(false);
        setPersonalize(false);
        checkAlreadyDone();
    };

    const showTheBanner = () => {
        setPersonalize(false);
        setShowBanner(true);
    };

    const handleClose = () => {
        if (done) {
            setPersonalize(false);
            setShowBanner(false);
        }
    };

    const bannerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (bannerRef.current && !bannerRef.current.contains(event.target)) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClose]);

    return showBanner ? (
        <div className="banner__container">
            <div className="banner" ref={bannerRef}>
                {done &&
                    <div className={`banner__header ${personalize ? "personalize" : ""}`}>
                        {personalize &&
                            <button className="banner__button"
                                onClick={() => setPersonalize(false)}>
                                {translations[locale].cookies.return}
                                <FontAwesomeIcon icon="fa-solid fa-angle-down" />
                            </button>
                        }
                        <button className="banner__button"
                            onClick={() => {
                                setShowBanner(false);
                                setPersonalize(false);
                            }}>
                            {translations[locale].cookies.close}
                        </button>
                    </div>
                }
                {!done && personalize &&
                    <div className={`banner__header no-consent ${personalize ? "personalize" : ""}`}>
                        <button className="banner__button"
                            onClick={() => setPersonalize(false)}>
                            {translations[locale].cookies.return}
                            <FontAwesomeIcon icon="fa-solid fa-angle-down" />
                        </button>
                    </div>
                }
                <h1 className="banner__title">
                    {translations[locale].cookies.title}
                </h1>
                <p className="banner__disclaimer">
                    {translations[locale].cookies.banner}&nbsp;<Link to="/cookies" className="banner__disclaimer__link">{translations[locale].footer.cookie}</Link>
                </p>
                {!personalize &&
                    <div className={`banner__buttons ${currentBreakpoint}`}>
                        <button className={`banner__buttons__button ${currentBreakpoint}`}
                            onClick={acceptCookies}>
                            {translations[locale].cookies.accept_all}
                        </button>
                        <button className={`banner__buttons__button personalize ${currentBreakpoint}`}
                            onClick={() => setPersonalize(true)}>
                            {translations[locale].cookies.personalize}
                        </button>
                    </div>
                }
                {personalize &&
                    <React.Fragment>
                        <p className="banner__disclaimers__disclaimer">
                            {translations[locale].cookies.disclaimer_choice}
                        </p>
                        <div className="banner__choice">
                            <div className="banner__choice__item">
                                Google reCAPTCHA
                                <div className="banner__choice__item__buttons">
                                    <button className={`banner__choice__item__buttons__button ${recaptcha ? "active" : ""}`}
                                        onClick={() => setRecaptcha(true)}>
                                        {translations[locale].cookies.accept}
                                    </button>
                                    <button className={`banner__choice__item__buttons__button ${!recaptcha ? "active" : ""}`}
                                        onClick={() => setRecaptcha(false)}>
                                        {translations[locale].cookies.reject}
                                    </button>
                                </div>
                            </div>
                            <div className="banner__choice__item">
                                Google Analytics
                                <div className="banner__choice__item__buttons">
                                    <button className={`banner__choice__item__buttons__button ${analytics ? "active" : ""}`}
                                        onClick={() => setAnalytics(true)}>
                                        {translations[locale].cookies.accept}
                                    </button>
                                    <button className={`banner__choice__item__buttons__button ${!analytics ? "active" : ""}`}
                                        onClick={() => setAnalytics(false)}>
                                        {translations[locale].cookies.reject}
                                    </button>
                                </div>
                            </div>
                            <div className="banner__choice__footer">
                                <button className="banner__buttons__button banner__buttons__button--personalize"
                                    onClick={acceptCookies}>
                                    {translations[locale].cookies.accept_all}
                                </button>
                                <button className="banner__choice__confirm"
                                    onClick={confirmCookies}>
                                    {translations[locale].cookies.confirm}
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    ) : (
        <button className={`banner--floating ${currentBreakpoint}`}
            onClick={showTheBanner}>
            <FontAwesomeIcon icon="fa-solid fa-cookie-bite" />
        </button>
    );
}